import React, { useEffect, useState } from "react";
import { Select, message } from "antd";
import axios from "util/Api";

import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";

const { Option } = Select;

const TagsSlectore = ({ id, relation, headers }) => {
  const [data, setData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSelected = () => {
    axios
      .get(`${REACT_APP_BASE_URL}${relation}/${id}/tags`, { headers })
      .then((res) => {
        setSelectedTags(res.data.data.map((item) => item.id));
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setSelectedTags([]);
      });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${REACT_APP_BASE_URL}tags`, { headers })
      .then((res) => {
        setData(res.data.data);
      })
      .then(() => {
        getSelected();
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    getSelected();
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const onDeselect = (val) => {
    axios
      .delete(`${REACT_APP_BASE_URL}tags/${val}/relation`, {
        headers,
        data: {
          type: relation,
          relation_id: id,
        },
      })
      .then(() => getSelected())
      .catch((err) => message.error(err.response.data.errors[0].msg));
  };

  const onSelect = (val) => {
    axios
      .post(
        `${REACT_APP_BASE_URL}tags/${val}/relation`,
        {
          type: relation,
          relation_id: id,
        },
        {
          headers,
        }
      )
      .then(() => getSelected())
      .catch((err) => message.error(err.response.data.errors[0].msg));
  };

  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="select tags"
      loading={loading}
      value={selectedTags}
      onSelect={onSelect}
      filterOption={(input, option) =>
        // option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.props.label.indexOf(input) >= 0
      }
      onDeselect={onDeselect}
      optionLabelProp="label"
    >
      {data.map((item) => (
        <Option key={item.id} value={item.id} label={item.name}>
          <div className="demo-option-label-item">{item.name}</div>
        </Option>
      ))}
    </Select>
  );
};

export default TagsSlectore;
