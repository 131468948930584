import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET,HEADER,USER_ID,USER_ROLE,USER_PERMITIONS} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: (localStorage.getItem('token')),
  initURL: '',
  authUser: (localStorage.getItem('user')),
  user_id: localStorage.getItem('user_id'),
  user_role:localStorage.getItem('user_role'),
  permitions:JSON.parse(localStorage.getItem('user_permitions'))|| 'all',
  header:{
    'Content-Type': 'application/json',
    'platform': 'admin-panel',
    'Authorization':localStorage.getItem('Authorization')}

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case HEADER : {
      return{
        ...state,
        header: action.payload
      }
    }

    case USER_ID: {
      return {
        ...state,
        user_id:action.payload
      }
    }
    case USER_ROLE: {
      return {
        ...state,
        user_role:action.payload
      }
    }

    case USER_PERMITIONS:{
      return {
        ...state,
        permitions:action.payload
      }
    }

    default:
      return state;
  }
}
