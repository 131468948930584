import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

const PageBreadCrumb = ({history}) =>{

    return(
        <Breadcrumb style={{marginBottom:'20px'}}>
            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
            {history.map((item,i)=><Breadcrumb.Item>
            <Link to={item.link} >{item.title}</Link>
            </Breadcrumb.Item>)}
            
            
        </Breadcrumb>
    )
}

export default PageBreadCrumb;

