import React, { Component, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  InputNumber,
  DatePicker,
  message,
  Input,
  Space,
} from "antd";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import axios from "util/Api";

import moment from "moment";

class ScheduleAvailablity extends Component {
  state = {
    date: "",
    validate: false,
    loading: false,
    notValidDate: false,
  };

  componentDidMount() {
    console.log(this.props);
    this.setState({ date: this.props.date });
  }

  handleOk = () => {
    const { date } = this.state;
    const { headers } = this.props;

    if (!date) {
      this.setState({ validate: true });
    } else {
      this.props.save(date);
    }
  };

  handleCancel = () => {
    this.props.cancel();
  };

  onDateChange = (value, dateString) => {
    const today = new Date();
    const day = new Date(dateString);

    if (today > day) {
      this.setState({ notValidDate: true });
    } else {
      this.setState({ date: dateString, notValidDate: false });
    }
  };

  render() {
    const { headers, visible } = this.props;
    const { date, validate, loading, notValidDate } = this.state;
    return (
      <Modal
        title={` Schedule Availablity `}
        visible={visible}
        onOk={this.handleOk}
        confirmLoading={loading}
        onCancel={this.handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Row justify="center">
          <Col>
            <label>
              <Space>
                <h4>
                  Availablity date <span className="req-astrick">*</span>
                </h4>
                <DatePicker
                  value={date ? moment(date) : ""}
                  onChange={this.onDateChange}
                />
                {validate && !date && <p className="error">Date is required</p>}
                {notValidDate && <p className="error">This date is invalid</p>}
              </Space>
            </label>
          </Col>
        </Row>
        <br />
      </Modal>
    );
  }
}

export default ScheduleAvailablity;
