import React from 'react';
import {REACT_APP_BASE_URL} from "../../../constants/ActionTypes";
import {useDispatch, useSelector} from "react-redux";
import {DeleteOutlined} from '@ant-design/icons';
import {Col,Button,Tooltip,Popconfirm,message} from "antd";
import Swal from 'sweetalert2';
import axios from 'axios';
import {updateDataTable} from '../../../appRedux/actions/Common';


const DeleteButton = ({id,name,email,action}) => {
  const headers= useSelector(({auth}) => auth.header);
  const dispatch = useDispatch();

   const onDelete = () => {
    Swal.fire({
      title: 'Are you sure you want to delete this user?',
      icon: 'warning',
      text:'Name: '+name+ '\n'+'Email:' +email,
      html:`<div style="text-align:start">
      <b style="margin-bottom:15px;width:50px">Name:</b> ${name}
      <br/><b style="margin-bottom:15px;width:50px">Email:</b> ${email}
      </div>`
      ,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'

    }).then((result) => {
     if (result.isConfirmed) {
      axios
      .delete(`${REACT_APP_BASE_URL}users/${id}/delete`,{headers})
      .then(()=>{message.success('Successfully deleted');})
      .then(() => {
        action();
        dispatch(updateDataTable(true))
      })

      }})}
  function cancel(e) {
    // console.log(e);
    // message.error('');
  }
  return (
    <div >

      <Tooltip placement="bottom" title='Delete'>
      <button className='icon-btn' style={{color:'red',fontSize:20}} onClick={onDelete} ><DeleteOutlined /></button>

       
      </Tooltip>


    </div>
  )
}

export default DeleteButton;
