import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import ProfileForm from '../../components/modal/profileUpdate';
import {updateProfile} from "../../appRedux/actions/Auth";

const UserInfo = () => {
  const dispatch = useDispatch();
  const headers= useSelector(({auth}) => auth.header);
  const user = useSelector(({auth}) => auth.authUser);
  const [visible, setVisible] = useState(false);

  const onCreate = values => {
    dispatch(updateProfile(values,headers));
    setVisible(false);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
       <li onClick={() => {
          setVisible(true);
        }}>Update Account</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <div>
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={"https://via.placeholder.com/150x150"}
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
       <ProfileForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>

  )

}

export default UserInfo;
