import React, {useState} from 'react';
import {Modal,Row,Col,Space,Button,Collapse} from 'antd';
import {ShrinkOutlined,MinusOutlined} from '@ant-design/icons';
import {deleteSubscriberTab} from '../../../appRedux/actions/Subscription';
import ActionsMenu from './actionsMenu';
import {useDispatch,useSelector} from 'react-redux';
import Subscription from './subcriptionActions/index';
import Gifts from './gifts/index'
import DeleteButton from './deleteUser';
import BasicInfo from './basicInfo/index';
import AddPoints from './subcriptionActions/addPoints';
import '../modal.css';
import SearchUser from './searchUser';

const { Panel } = Collapse;

const EditSubscriptionModal = (props) =>{
    const dispatch = useDispatch();
    const[id,setId] = useState(props.id);
    const [visible,setVisible] = useState(props.visible);
    const [data,setData] = useState(props.data)
    const action= props.action;
    const [selectedContent, setSelectedContent] = useState(1)
    const headers = useSelector(({auth})=>auth.header)
    const handleSlectedContent = (selected)=>{
        setSelectedContent(selected);
    }

    const onUserSearched = (userData) =>{
        setData(userData);
        console.log(3333,userData,data)
        setId(userData.id);
    }

    const onCancel = () =>{
        dispatch(deleteSubscriberTab(id))

        action()
    }

    const getContent = () =>{
        switch (selectedContent) {
            case 1:
                return(<BasicInfo data={data} headers={headers}/>)
            case 2:
                return( <Subscription data={data} headers={headers}/> )
            case 3:
                return( <Gifts data={data} headers={headers}/> )
            default:
                break;
        }
    }
    return(
        <Modal 
        maskClosable={false}
        width={700}
        style={{top:10}}
        className='edit-subscription-modal' 
        title={<Row justify='space-between'>
            <Col span={5} className='ant-modal-title '>Subscriber</Col>
            <Col span={16}><SearchUser action={onUserSearched} id={id} email={data.email} data={data} /></Col>
            <Col>
            <Space>
                <button className='icon-btn' onClick={()=>action()} ><MinusOutlined /></button>
                <button className='icon-btn' onClick={()=>{
                    action()}} ><ShrinkOutlined /></button>
            </Space>
            </Col>
        </Row>} 
        footer={
            <Row justify='space-between' align='middle' style={{padding:'0 110px 0 20px'}}>
                <Col>
                <Space>
                    <DeleteButton id={id} action={onCancel} email={data.email} name={data.name}/>
                    {/* <ActionsMenu action={handleSlectedContent} /> */}
                </Space>
                </Col>
                <Col>
                  <Space>
                  {/* <Button style={{marginLeft:"8px"}} key="back" 
                  onClick={() => 
                    { 
                     dispatch(deleteSubscriberTab(id))
                    action()}}>
                    cancel
                    </Button> */}
                    
                  </Space>
                </Col>
            </Row>
            
        }
        visible={visible} 
        onCancel={()=>{
            dispatch(deleteSubscriberTab(id))

            action()
        }}
        >
            {/* <Row gutter={40}>
                <Col span={12}><BasicInfo data={data} headers={headers}/></Col>
                <Col span={12}><AddPoints data={data} headers={headers}/></Col>
            </Row>
            <br/><br/>
            <Row gutter={40}>
                <Col span={12}><Subscription data={data} headers={headers}/></Col>
                <Col span={12}><Gifts data={data} headers={headers}/> </Col>
            </Row> */}
            <Row ><Col span={12}>
                <h4> User: <span style={{fontWeight:500,marginLeft:'20px',textTransform:'none'}}>{data.email}</span></h4>
            </Col>
            <Col span={8}>
            <h4> User Id: <span style={{fontWeight:500,marginLeft:'9px',textTransform:'none'}}>{data.id}</span></h4>

            </Col>
            </Row>
            <br/>
            <Collapse accordion defaultActiveKey={'1'} >
                <Panel header="Basic Info" key="1">
                <BasicInfo data={data} headers={headers}/>
                </Panel>
                <Panel header="Add Points" key="2">
                <AddPoints data={data} headers={headers}/>
                </Panel>
                <Panel header="Subscription" key="3">
                <Subscription data={data} headers={headers}/>
                </Panel>
                <Panel header="Gifts" key="4">
                <Gifts data={data} headers={headers}/>
                </Panel>
            </Collapse>
           
        
        </Modal>
    )
}

export default EditSubscriptionModal