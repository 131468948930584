import React,{useEffect,useState} from 'react';
import './style.css';

const UploadImg = ({getParentElem,imgUrl,endPoint}) => {

  return (
    <div className='upload_img' onClick={()=>{
      if (endPoint){
      localStorage.setItem('endPoint', endPoint)}

      getParentElem()
      }}
    >

      <img
      style={{maxHeight:'150px'}}
      alt='book_img'
      src={imgUrl ||'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'}/>
    </div>
  )
}

export default UploadImg;

