import React,{Component} from 'react';
import {Row,Col, Input, Switch,Button, message,Space, } from 'antd';
import DiscardButton from '../../../components/buttons/discard';
import CK from '../../../components/editers/ck';
import {REACT_APP_BASE_URL} from "../../../constants/ActionTypes";
import UploadImg from '../../../components/Images/uploadimg';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import TagsSlectore from '../../../components/inputs/tagsSelector';

const uploadRef = React.createRef();

class Info extends Component {

      state = {
        title: "",
        name: "",
        image_url:"",
        desc:"",
        id: this.props.id ,
        status:this.props.status,
        loading:false,
        headers:this.props.headers,
        url:this.props.url,
        redirect:false,
        valid:false,
        available:false
      }

    componentDidMount() {
      const {url,headers} = this.state
      const {status} = this.props
      localStorage.setItem('book_img','')
      //update state when there is props
      if(status !=='create'){
        let id;
        if(!this.props.id){

          id = localStorage.getItem('id').toString()
        }
        else{
          id =this.props.id
        }

        axios
        .get(url+'/'+ id,{headers})
        .then((res)=> {
          const data = res.data.data;
          this.setState({
            title:data.title,
            name:data.name,
            image_url:data.image_url,
            desc:data.desc,
            id:data.id,
            available:data.available
          })})
      }
    }


    onSave = () => {
      this.setState({loading:true})
      const {
        title,
        name,
        desc,
        image_url,
        headers,
        status,
        id,
        url,available
        } = this.state;

        if (name === '' || title === '') {
          this.setState({valid:true, loading:false})
        }
        else{
        if (status === 'create') {
          axios
          .post(url,{
            title,
            name,
            available,
            desc:localStorage.getItem('desc')|| desc,
            image_url},
            {headers})
          .then (res =>{
            localStorage.setItem('status', 'edit')
            localStorage.setItem('id', res.data.data.curated_id);
            this.setState({id:res.data.data.curated_id});
            this.props.onRefresh(true);
          })
          .then(()=> {
            this.setState({
              loading:false,
              redirect:true,
              status:'edit',
              },()=>{
               message.success('Successfully Created ')
              })})
          .catch(err =>{
            this.setState({
              loading:false,})
            message.error(err.response.data.errors[0].msg)
          })}
        if (status === 'edit') {
         const url = this.state.url +'/'+ id;
          axios
          .put(
            url,
            {title,
            name,
            available,
            desc:localStorage.getItem('desc')|| desc,
            image_url},
            {headers}
          )
          .then (res => {this.setState({
            loading:false,
            status:'edit',
            },()=>{
             message.success('Successfully Modified ')
            })})
        .catch(err => {
          this.setState({loading:false})
          message.error(err.response.data.errors[0].msg)
        })
      }
    }
    }

    handelChange = (e) => {
      e.preventDefault();
      
      const{ title,title_ar}=this.state
      //Handle validation
      if(e.target.name === 'title' ) {
        if(e.target.value === ''){
          this.setState({titleImpty: true})
        }
        else{
          this.setState({titleImpty: false})
        }
      }
      if (e.target.name === 'name' ) {
        if(e.target.value === ''){
          this.setState({nameImpt:true})
        }
        else {
          this.setState({nameImpt:false})
        }
      }

      this.setState({[e.target.name]: e.target.value});
    }

    handleFile = e => {
      const formData = new FormData();
      const headers = this.state.headers
      formData.append('width', 500);
      formData.append('height', 500);
      formData.append('file', e.target.files[0]);
      axios
      .post(`${REACT_APP_BASE_URL}uploads/curated/img`, formData, { headers })
      .then (res => {
        this.setState({image_url:res.data.data.file_url,loading:false})
      })

    }

    renderRedirect = () => {
      if (this.state.redirect) {
        return <Redirect to={{pathname:`/curated_book/${this.state.id}`,aboutProps:{status:'edit',data:this.state}}} />
      }
    }


  render() {
    const {
      title,
      name,
      id,
      headers,
      desc,
      image_url,
      loading,
      valid,
      available
      } = this.state;
    return (
      <div style={{margin:'10px '}}>
        {this.renderRedirect()}
        <Row justify='space-between' >
          <Col xs={24} md={12} >
            <label>
            <h4>Title <span className='req-astrick'>*</span></h4>
            <Input  defaultValue={title} value={title} name='title' onChange={this.handelChange}/>
            {valid && !title && <p style={{color:'red'}}>This field is required!</p>}
            </label>
            <br/><br/>
            <label>
            <h4>Name <span className='req-astrick'>*</span></h4>
            <Input  defaultValue={name} value={name} name='name' onChange={this.handelChange}/>
            {valid && !name && <p style={{color:'red'}}>This field is required!</p>}
            </label>
            <br/> <br/>

            <label>
            <h4>Short Description</h4>
            <CK name='desc' data={desc}/>
            </label>
          </Col>

      <Col xs={18} md={8} style={{alignItems:'center',marginLeft: 'auto',marginRight: 'auto'}}>

        <input type='file' id='book_img' onChange={this.handleFile} ref={e=>uploadRef.current=e} style={{display:'none'}} />
        <UploadImg
        getParentElem={() => uploadRef.current.click()}
        imgUrl={image_url}/>
        <br/> <br/>
        {id&&<><label>
          <h4>Tags</h4>
          <TagsSlectore relation='curated' id={id} headers={headers}/>

        </label>
        <br/> <br/></>}
        <label>
            <h4>Available <span className='req-astrick'>*</span></h4>
            <Switch checked={available} onChange={(checked)=>this.setState({available:checked})} />
            </label>
            
      </Col>
    </Row>
      <br/><br/>
    <Row justify='center'  >
      <Space>
        <DiscardButton endpoint='/curated'/>
        <Button  onClick={this.onSave} type="primary" loading={loading}>Save</Button>
      </Space>

    </Row>

      </div>
    )
  }


}

export default Info;
