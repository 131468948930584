import React, { useEffect, useState } from "react";
import { REACT_APP_BASE_URL } from "../../../../constants/ActionTypes";
import { message, Row, Col, Spin, Button, Tooltip, Space, Popover } from "antd";
import axios from "util/Api";

import timeConverter from "../../../common/timeConverter";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

const ListGifts = ({ headers, data, updated, updateGifts }) => {
  const [data1, setData1] = useState([]);

  const getGifts = () => {
    axios
      .get(
        `${REACT_APP_BASE_URL}gifts/sent?page=1&size=30&user_id=${data.id}`,
        { headers }
      )
      .then((res) => {
        if (res.data) {
          setData1(res.data.data.list);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err.response));
  };

  useEffect(() => {
    getGifts();
  }, []);

  useEffect(() => {
    if (updateGifts) {
      getGifts();
      updated();
    }
  });
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <label>
            <h4>Status</h4>
          </label>
        </Col>
        <Col span={8}>
          <label>
            <h4>Duration</h4>
          </label>
        </Col>
        <Col span={8}>
          <label>
            <h4>Link</h4>
          </label>
        </Col>
      </Row>
      {data1.length > 0 ? (
        <Scrollbars style={{ height: 200, width: "100%", overflowX: "hidden" }}>
          {data1.map((item, i) => (
            <Row
              style={{
                padding: "20px 10px",
                backgroundColor: `${i % 2 !== 0 ? "white" : "#F5F5F5"}`,
                width: "100%",
              }}
            >
              <Col span={8}>
                <h5>{item.gift_status}</h5>
              </Col>
              <Col span={8}>
                <h5>{timeConverter(item.subscription_duration)}</h5>
              </Col>
              <Col span={8}>
                <h5>
                  <Space>
                    copy link
                    <CopyToClipboard
                      text={`https://www.wajeez.co/gifts?gift_token=${item.token}`}
                    >
                      <Tooltip title="copy to clipbord">
                        <Button icon={<CopyOutlined />} />
                      </Tooltip>
                    </CopyToClipboard>
                  </Space>
                </h5>
              </Col>
            </Row>
          ))}
        </Scrollbars>
      ) : (
        <h2 style={{ textAlign: "center", margin: "60px auto" }}>No gifts</h2>
      )}
    </>
  );
};

export default ListGifts;
