import React,{Component} from 'react';
import { Modal,Row,Col,InputNumber,DatePicker,message,Input,Space } from 'antd';
import axios from 'axios';
import {REACT_APP_BASE_URL} from '../../../../constants/ActionTypes';
import SaveButton from '../saveButton';

class AddPoints extends Component {
  state = {
    name:this.props.data.name,
    email:this.props.data.email,
    oldPoints:this.props.data.points,
    points:0,
    expire_after_ms:null,
    loading:false,
    reason:'',
    numberSelect:0,
    validate:false
  }


  handleOk= () => {
    const {headers} = this.props
    const {points,email,expire_after_ms,reason} = this.state
    if (expire_after_ms === null || reason === '') {
      this.setState({validate:true})
    }
    else{
    this.setState({loading:true},()=> {
      axios
      .post(`${REACT_APP_BASE_URL}users/points`,{points,email,expire_after_ms,reason},{headers})
      .then(()=>{
        message.success('The points has been added')
        this.setState({loading:false})
        // this.props.updated();
        // this.props.action();

      })
      .catch(err=>this.setState({loading:false}))

    })}
  }

  handleCancel= () => {
    this.props.action()
  }

  onChange = (value) => {

    if (value < 0) {
      message.error('You cant add a nigative numbers')
    }
    const numberSelect= value>0 ? value: 0
    const oldPoints = this.props.data.points +value
    const points = numberSelect;
    this.setState({points,oldPoints,numberSelect})
  }

  onDateChange = (value, dateString) => {
    const oldDate = Date.now()
    const date = new Date(dateString)
    this.setState({expire_after_ms:(date.getTime())})

  }

  onReason = (e) => {
    e.preventDefault()
    this.setState({reason:e.target.value})
  }
  render() {
    const {headers,visible} = this.props;
    const {loading,name,points,oldPoints,numberSelect,reason,expire_after_ms,validate} = this.state;
    const text = oldPoints===1 ? 'point': 'points'
    return(
      < >
      <Row justify="space-between">
        <Col span={12}>
        <label>
        <h4>Add points</h4>
        <Space >
        <InputNumber
        type="number"
         min={0}
         value={numberSelect}
        defaultValue={numberSelect}
         onChange={this.onChange} />
         {oldPoints + "  " + text}
        </Space>
        </label>
        </Col>

        <Col span={12}>
        <label>
          <h4>Experation date <span className='req-astrick'>*</span></h4>
          <DatePicker  onChange={this.onDateChange} />
          {validate && expire_after_ms === null && <p>Date is required</p>}
        </label>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={24}>
          <label>
          <h4>Reason <span className='req-astrick'>*</span></h4>
          <Input  defaultValue={reason} value={reason} onChange={this.onReason}/>
          {validate && reason === '' && <p>Reason is required</p>}
          </label>
        </Col>

      </Row>
      <br/><br/>
      <div style={{textAlign:'center'}}>
      <SaveButton loading={loading} action={this.handleOk} name='add points'/>
      </div>
        
      </>
    )
  }

}

export default AddPoints;
