import React, { useState, useEffect } from "react";
import { Input, Select, message, Row, Col } from "antd";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import axios from "util/Api";

import timeConverter from "../../common/timeConverter";
import SaveButton from "./saveButton";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { updateDataTable } from "../../../appRedux/actions/Common";

const { Option } = Select;

const CreateSubOrGiftFrom = ({ endPoint, type, data, headers, updated }) => {
  const [values, setValues] = useState({
    user_id: data.id,
    provider: "",
    product_id: null,
    transaction_id: "",
  });
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (count === 0) {
      axios
        .get(`${REACT_APP_BASE_URL}subscription/providers`, { headers })
        .then((res) => {
          setValues({ ...values, provider: res.data.data[0].platform });
          setProviders(res.data.data);
          setCount(1);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${REACT_APP_BASE_URL}subscription/products/${values.provider}?type=${type}`,
        { headers }
      )
      .then((res) => {
        if (res.data.data) {
          let response = res.data.data.map((item) => {
            return {
              id: item.id,
              duration: timeConverter(item.duration),
              type: item.sku,
              default_price: item.default_price,
            };
          });
          console.log(555, response);
          setProducts(response);
          setValues({ ...values, product_id: response[0].id });
        } else {
          console.log(555, res.data.data);
          setProducts([]);
          setValues({ ...values, product_id: null });
        }
      })
      .catch((err) => console.log(err));
  }, [providers, values.provider]);

  const onOk = () => {
    axios
      .post(`${REACT_APP_BASE_URL}${endPoint}`, values, { headers })
      .then((res) => {
        if (type === "gift") {
          Swal.fire({
            icon: "success",
            title: "Successfully created",
            text: "Gift Token: " + res.data.data,
          });
        } else {
          message.success("successfully created");
        }

        updated();
      })
      .then(() => dispatch(updateDataTable(true)))
      .catch((err) => message.error(err.response.data.errors[0].msg));
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <label>
            <h4>
              Provider<span className="req-astrick">*</span>
            </h4>

            <Select
              style={{ width: "100%" }}
              onChange={(val) =>
                handleChange({ target: { name: "provider", value: val } })
              }
              name="provider"
              disabled={!values.provider}
              loading={!values.provider}
              value={values.provider}
            >
              {providers.map((item, i) => (
                <Option key={i} value={item.platform}>
                  {item.platform}
                </Option>
              ))}
            </Select>
          </label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <label>
            <h4>
              Product<span className="req-astrick">*</span>
            </h4>

            <Select
              style={{ width: "100%" }}
              onChange={(val) =>
                handleChange({ target: { name: "product_id", value: val } })
              }
              name="product_id"
              value={values.product_id}
            >
              {products.map((item, i) => (
                <Option key={i} value={item.id}>
                  {item.type +
                    " , " +
                    item.duration +
                    " , " +
                    item.default_price +
                    " USD"}
                </Option>
              ))}
            </Select>
          </label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <label>
            <h4>Transaction Id</h4>
            <Input.TextArea
              value={values.transaction_id}
              rows={4}
              name="transaction_id"
              onChange={handleChange}
            />
          </label>
        </Col>
      </Row>
      <br />
      <br />
      <SaveButton loading={loading} action={onOk} name="create subscription" />
    </>
  );
};

export default CreateSubOrGiftFrom;
