import React, { Component } from "react";
import Player from "../inputs/AudioPlayer.js";
import { Button, Spin, Row, Col, Space, Popconfirm, message } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes.js";
import "./audioStyle.css";
import { EyeOutlined } from "@ant-design/icons";

const InputRef = React.createRef();

class UploadVideo extends Component {
  state = {
    loading: false,
    selectedFile: null,
    msg: "",
    color: "",
    src: this.props.video_url,
    del: true,
  };

  onFileChange = (event) => {
    console.log(event.target.files[0]);
    this.setState(
      { selectedFile: event.target.files[0], loading: true },
      () => {
        this.onFileUpload();
      }
    );
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    const { endpoint, headers, id } = this.props;
    const formData = new FormData();
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    axios
      .post(`${REACT_APP_BASE_URL}uploads/${endpoint}/videos`, formData, {
        headers,
      })
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ src: res.data.data.file });
        this.setState({ del: true });
      })
      .catch((err) => {
        this.setState({ loading: false });
        err.response.data.errors.map((error) => message.error(error.msg, 5));
      });
  };

  render() {
    const { src, loading, del } = this.state;
    return (
      <div>
        <input
          type="file"
          accept="video/mp4"
          onChange={this.onFileChange}
          ref={InputRef}
          style={{ display: "none" }}
        />
        <Space align="center">
          {loading ? (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          ) : (
            <Button
              icon={<UploadOutlined />}
              onClick={() => InputRef.current.click()}
            >
              Upload Video
            </Button>
          )}

          {src || this.props.video_url ? (
            <a href={src || this.props.video_url} target="_blank">
              <EyeOutlined style={{ fontSize: "32px" }} />
            </a>
          ) : (
            <div></div>
          )}
        </Space>
      </div>
    );
  }
}

export default UploadVideo;
