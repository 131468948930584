import {
    DELETE_SUBSCRIBER_TAB,
    ADD_SUBSCRIBER_TAB,
    UPDATE_SUBSCRIBER_TAB,
    REVIEW_SUBSCRIBER_TAB} from "../../constants/ActionTypes";



export const addSubscriberTab = (obj) => {

    return (dispatch) => {
  
         
        dispatch({type: ADD_SUBSCRIBER_TAB,payload:obj});
  
    }
};

export const updateSubscriberTab = (oldData,newData) => {
console.log(oldData,newData)
    return (dispatch) => {
  
         
        dispatch({type: UPDATE_SUBSCRIBER_TAB,payload:{oldData,newData}});
  
    }
};

export const deleteSubscriberTab = (id) => {

    return (dispatch) => {
  
         
        dispatch({type: DELETE_SUBSCRIBER_TAB,payload:id});
  
    }
};


