import React, {Component} from 'react';
import {Button, Spin,message} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';



const InputRef = React.createRef();

class UploadChapters extends Component  {
  state = {
    loading: false ,
    selectedFile: null,
    msg:'',
    color:''
  };

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0],loading:true },()=>{this.onFileUpload()});
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    const {url,headers,id} = this.props
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    this.props.action();
    axios
    .post(`${url}/${id}/upload/chapters/zip`, formData , {headers})
    .then(res =>{
      message.success('Successfully uploaded',5)
      this.props.action();
    })
    .catch(err=>{
      this.setState({loading:false});
      console.log(err.response)
      this.props.action();
      err.response.data.errors.map(error =>message.error(error.msg,5))
    })


  };

  render() {
  return(
    <div>

      <input type="file" onChange={this.onFileChange} ref= {InputRef} style={{display:'none'}}/>
      <Button icon={<UploadOutlined />} onClick={() => InputRef.current.click()} >Upload Chapters</Button>

    </div>
  )}
}

export default UploadChapters;
