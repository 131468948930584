import React, { Component, createRef } from "react";
import {
  Row,
  Col,
  Input,
  Switch,
  Button,
  Select,
  Space,
  Popconfirm,
  message,
  Spin,
  Tooltip,
} from "antd";
import UploadImage from "./UploadImge";
import DiscardButton from "../../../components/buttons/discard";
import axios from "util/Api";
import UploadImg from "../../../components/Images/uploadimg";
import { Redirect } from "react-router-dom";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.css";
import ScheduleAvailablity from "../../../components/modal/ScheduleAvailablity";
import getIsoDate from "./isoDateFormat";
import SearchInput from "../../../components/inputs/StanderdSearchInput";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import VoiceOversModal from "../../../components/modal/VoiceOversModal";
const { Option } = Select;
const bookTypesList = [
  { label: "Standard", value: "standard" },
  { label: "Novel", value: "novel" },
  { label: "Short description", value: "short" },
  { label: "Holy Quran", value: "quran" },
  { label: "Program ", value: "program" },
  { label: "Program with contest", value: "contest_program" },
  { label: "Theater Play", value: "theater_play" },
];

const uploadRef = React.createRef();

class BasicInfo extends Component {
  state = {
    imgLoading: "",

    schedVisible: false,
    title: "",
    title_ar: "",
    sub_title: "",
    sub_title_ar: "",
    image_url: "",
    is_locked: true,
    id: this.props.id,
    status: this.props.status,
    loading: false,
    headers: this.props.headers,
    url: this.props.url,
    msg: "",
    type: "standard",
    messageType: "",
    redirect: false,
    available: false,
    validation: false,
    voice_over_ids: [],
    dateAv: getIsoDate(),
    keywords: [],
    voiveOvermodalVisiblity: false,
    updateVoiceOversList: false,
  };

  componentDidMount() {
    const { url, headers } = this.state;
    const { status, type } = this.props;
    //update state when there is props
    if (status !== "create") {
      let id = this.props.id;

      axios
        .get(this.state.url + "/" + id, { headers })
        .then((res) => {
          const data = res.data.data;
          this.setState({
            title_ar: data.title_ar,
            title: data.title,
            sub_title_ar: data.sub_title_ar,
            sub_title: data.sub_title,
            image_url: data.image_url,
            is_locked: data.is_locked,
            id: data.id,
            available: data.available,
            type: data.type,
            dateAv: data.publish_at,
            keywords: data.keywords ? data.keywords.split(",") : [],
          });
        })
        .then(() =>
          axios
            .get(`${REACT_APP_BASE_URL}book/${this.state.id}/voice_overs`, {
              headers,
            })
            .then((res) =>
              this.setState({
                voice_over_ids: res.data.data.map((item) => item.id),
              })
            )
        )
        .catch((err) => console.log(err));
    } else if (type) {
      this.setState({ type });
    }
  }

  handleVoiceOverSelect = (val) => {
    const { status, voice_over_ids, id, headers } = this.state;
    if (status === "create") {
      this.setState({ voice_over_ids: val });
    } else {
      if (val.length > voice_over_ids.length) {
        let voiceOverId = val.filter((item) => !voice_over_ids.includes(item));
        axios
          .post(
            `${REACT_APP_BASE_URL}relation/${id}/voice_overs`,
            { type: "book", voice_over_ids: voiceOverId },
            { headers }
          )
          .then(() => this.setState({ voice_over_ids: val }));
      } else {
        let voiceOverId = voice_over_ids.filter((item) => !val.includes(item));
        console.log(val, voice_over_ids, headers);
        axios
          .delete(`${REACT_APP_BASE_URL}relation/${id}/voice_overs`, {
            headers,
            data: { type: "book", voice_over_ids: voiceOverId },
          })
          .then(() => this.setState({ voice_over_ids: val }));
      }
    }
  };

  onSave = () => {
    this.setState({ loading: true });
    const {
      title,
      title_ar,
      sub_title,
      sub_title_ar,
      image_url,
      is_locked,
      headers,
      status,
      id,
      url,
      type,
      keywords,
      voice_over_ids,
    } = this.state;

    this.setState({ loading: true });
    if (title === "" || title_ar === "") {
      this.setState({ loading: false, validation: true });
    } else {
      if (status === "create") {
        axios
          .post(
            url,
            {
              keywords: keywords.toString(),
              title,
              title_ar,
              sub_title,
              sub_title_ar,
              type,
              image_url: image_url,
              is_locked,
            },
            { headers }
          )
          .then((res) => {
            this.setState({ id: res.data.data.book_id });
            this.props.getData(res.data.data.book_id);
            this.props.onRefresh(true);
          })
          .then(() => {
            this.setState(
              {
                loading: false,
                redirect: true,
                status: "edit",
                messageType: "success",
              },
              () => {
                message.success("Successfully Created");
              }
            );
          })
          .catch((err) => {
            message.error(err.response.data.errors[0].msg);
            this.setState({ loading: false, messageType: "error" });
          });
      }
      if (status === "edit") {
        const url = this.state.url + "/" + id;

        axios
          .put(
            url,
            {
              keywords: keywords.toString(),
              title,
              title_ar,
              sub_title,
              sub_title_ar,
              type,
              image_url: image_url,
              is_locked,
            },
            { headers }
          )
          .then((res) => {})
          .then(() =>
            this.setState(
              {
                loading: false,
                messageType: "success",
              },
              () => {
                message.success("Successfully updated");
              }
            )
          )
          .then(() => {
            this.props.getData(id);
          })
          .catch((err) => {
            message.error(err.response.data.errors[0].msg);
            this.setState({ loading: false, messageType: "error" });
          });
      }
    }
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: `/book/${this.state.id}`,
            aboutProps: { status: "edit", data: this.state },
          }}
        />
      );
    }
  };

  handelChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleType = (value) => {
    this.setState({ type: value });
  };

  handelIslokedToggle = (checked, e) => {
    this.setState({ is_locked: checked });
  };

  handelAvailableToggle = (checked, e) => {
    const { headers, id, url, available, dateAv } = this.state;
    axios
      .post(
        `${url}/${id}/availability`,
        {
          available: available == 0 ? true : false,
          publish_at: dateAv,
        },
        { headers }
      )
      .then(() => {
        message.success(
          `this book now is ${available == 0 ? "available" : "not available"}`
        );
      })
      .then(() =>
        this.setState({
          available: dateAv > getIsoDate() ? false : !available,
        })
      )
      .catch((err) => {
        err.response.data.errors.map((error) => message.error(error.msg, 5));
      });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { headers } = this.props;
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    this.setState({ imgLoading: true }, () => {
      axios
        .post(`${REACT_APP_BASE_URL}uploads/program/img`, formData, {
          headers,
        })
        .then((res) =>
          this.setState(
            {
              image_url: res.data.data.file_url,
            },
            () => this.setState({ imgLoading: false })
          )
        )
        .catch((err) => {
          this.setState({ imgLoading: false });
          message.error(err.response.data.errors[0].msg);
        });
    });
  };

  render() {
    const {
      validation,
      title,
      title_ar,
      sub_title,
      sub_title_ar,
      image_url,
      is_locked,
      type,
      keywords,
      voiveOvermodalVisiblity,
      voice_over_ids,
      available,
      imgLoading,
      loading,
      schedVisible,
      headers,
    } = this.state;
    const { status } = this.props;
    return (
      <div style={{ margin: "10px " }}>
        {this.renderRedirect()}
        <Row justify="space-between">
          <Col xs={24} md={12}>
            <label>
              <h4>
                Book Name <span className="req-astrick">*</span>
              </h4>
              <Input
                defaultValue={this.state.title}
                value={title}
                name="title"
                onChange={this.handelChange}
              />
              {validation && !title && (
                <p style={{ color: "red" }}>This field is required!</p>
              )}
            </label>
            <br />
            <br />
            <label>
              <h4>
                Book Name Arabic <span className="req-astrick">*</span>
              </h4>
              <Input
                defaultValue={title_ar}
                value={title_ar}
                name="title_ar"
                onChange={this.handelChange}
              />
              {validation && !title_ar && (
                <p style={{ color: "red" }}>This field is required!</p>
              )}
            </label>
            {this.state.type !== "quran" && (
              <>
                <br />
                <br />
                <label>
                  <h4>Sub Title</h4>
                  <Input
                    defaultValue={sub_title}
                    value={sub_title}
                    name="sub_title"
                    onChange={this.handelChange}
                  />
                </label>
                <br />
                <br />
                <label>
                  <h4>Sub Title Arabic</h4>
                  <Input
                    defaultValue={sub_title_ar}
                    value={sub_title_ar}
                    name="sub_title_ar"
                    onChange={this.handelChange}
                  />
                  <br />
                  <br />
                </label>
                <label>
                  <h4>Keywords</h4>
                  <Select
                    mode="tags"
                    value={keywords}
                    style={{ width: "100%" }}
                    onChange={(value) => this.setState({ keywords: value })}
                  ></Select>
                </label>
              </>
            )}
            <br />
            <br />
            <label>
              <h4>Type</h4>
              <Select
                defaultValue={type}
                value={type}
                style={{ width: "100%" }}
                onChange={this.handleType}
              >
                {bookTypesList.map((item, i) => (
                  <Option value={item.value} key={i}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </label>
            <br />
            <br />

            <Space>
              <label>
                <Space>
                  <Space>
                    <h4>Is Locked</h4>
                    <Switch
                      key="Is_locked"
                      defaultChecked={is_locked}
                      checked={is_locked}
                      onChange={this.handelIslokedToggle}
                    />
                  </Space>
                </Space>
              </label>
              {status !== "create" && (
                <Space>
                  <label>
                    <Space>
                      <h4>Available </h4>
                      <Popconfirm
                        placement="topLeft"
                        title={
                          "Are you sure you want to change available status?"
                        }
                        onConfirm={() => {
                          this.setState({ dateAv: getIsoDate() }, () =>
                            this.handelAvailableToggle()
                          );
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Switch
                          defaultChecked={available}
                          checked={available}
                          key="available"
                        />
                      </Popconfirm>
                    </Space>
                  </label>
                  <Tooltip title="schedule availablity">
                    <Button
                      onClick={() =>
                        this.setState({ schedVisible: !schedVisible })
                      }
                      type="dashed"
                      shape="circle"
                      size="small"
                      icon={<CalendarOutlined />}
                      className="sched-btn"
                    />
                  </Tooltip>
                </Space>
              )}
            </Space>
            <br />
          </Col>
          <Col xs={18} md={12}>
            <>
              {this.state.type !== "quran" && (
                <label style={{ textAlign: "start" }}>
                  <h4>Voice overs names</h4>
                  <Row>
                    <Col span={18}>
                      <SearchInput
                        placeholder=""
                        isMulti={true}
                        update={this.state.updateVoiceOversList}
                        updated={() => {
                          this.setState({ updateVoiceOversList: false });
                        }}
                        value={voice_over_ids}
                        url={"voice_overs/datatable"}
                        headers={headers}
                        label="name"
                        filter={[]}
                        getValue={this.handleVoiceOverSelect}
                      />
                    </Col>
                    <Col span={4}>
                      <Tooltip placement="top" title={"Add new voice over"}>
                        <PlusOutlined
                          className="icon-btns"
                          onClick={() =>
                            this.setState({ voiveOvermodalVisiblity: true })
                          }
                          style={{ cursor: "pointer", fontSize: "24px" }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </label>
              )}
              <br /> <br />
              {type === "contest_program" || type === "program" ? (
                <div>
                  <input
                    type="file"
                    id="chapter_img"
                    onChange={this.handleFile}
                    ref={(e) => (uploadRef.current = e)}
                    style={{ display: "none" }}
                  />
                  <Spin spinning={imgLoading}>
                    {" "}
                    <UploadImg
                      getParentElem={() => uploadRef.current.click()}
                      endPoint={"chapter_img"}
                      imgUrl={image_url}
                    />
                  </Spin>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "80%",
                  }}
                >
                  <UploadImage
                    action={(name, val) => {
                      this.setState({ [name]: val });
                    }}
                    img_url={image_url}
                    name={"image_url"}
                    status="create"
                    endPoint=""
                    height="300px"
                    width="300px"
                  />
                </div>
              )}
            </>
          </Col>
        </Row>
        <br />
        <br />
        <Row justify="center">
          <Space>
            <DiscardButton endpoint="/booksManager" />
            <Button onClick={this.onSave} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        </Row>
        {schedVisible && (
          <ScheduleAvailablity
            headers={headers}
            visible={schedVisible}
            date={this.state.dateAv}
            cancel={() => {
              this.setState({ schedVisible: false });
            }}
            save={(date) => {
              this.setState({ dateAv: date, schedVisible: false }, () => {
                this.handelAvailableToggle();
              });
            }}
          />
        )}
        {voiveOvermodalVisiblity && (
          <VoiceOversModal
            title="Create new voiceover"
            headers={headers}
            action={(data) => {
              message.success("Successfully created");
              this.setState({ voiveOvermodalVisiblity: false });
              this.handleVoiceOverSelect([...voice_over_ids, data.id]);
            }}
            visible={voiveOvermodalVisiblity}
            status="create"
            updated={() => {
              this.setState({ updateVoiceOversList: true });
            }}
          />
        )}
      </div>
    );
  }
}

export default BasicInfo;
