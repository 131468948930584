import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


const Player = ({src}) =>{

return (
  <AudioPlayer
  autoPlayAfterSrcChange={false}
  src={src}
  loop={false}
  autoPlay={false}
  volume={1.0}
  format="hh:mm:ss"
  />
)}

export default Player;

