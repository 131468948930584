import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import maintenace from "../assets/images/website-maintenance.svg";
const MaintenacePage = () => {
  return (
    <Result
      icon={<img src={maintenace || ""} alt="" width={500} />}
      title="We are under maintenance"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};

export default MaintenacePage;
