import React, { useState, useEffect } from "react";
import BasicInfo from "./BasicInfo";
import { useSelector } from "react-redux";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import Details from "./Details";
import Chapters from "./Chapters";
import { Prompt, useHistory, useParams } from "react-router-dom";
import axios from "util/Api";

import Swal from "sweetalert2";
import { Tabs, Card } from "antd";
import ViewChapters from "./viewChapterTab";
import RelatedBooks from "./RelatedBooks";
import BookNotification from "./bookNotification";
const { TabPane } = Tabs;
const Modify = (props) => {
  const headers = useSelector(({ auth }) => auth.header);
  const history = useHistory();
  const { id, type } = useParams();
  const url = REACT_APP_BASE_URL + "books";
  const [data, setData] = useState(
    props.location.aboutProps ? props.location.aboutProps.data : ""
  );
  const [id1, setId] = useState(props.match.params.id || null);
  const [state, setState] = useState(id ? "edit" : "create");
  const [disabled, setDisabled] = useState(id ? false : true);
  const [count, setCount] = useState(0);
  const [prompt, setPrompt] = useState(true);
  const [updateChapters, setUpdateChapters] = useState(false);

  useEffect(() => {});

  useEffect(() => {
    if (id) {
      getData(id);
    } else {
      setDisabled(true);
    }
  }, []);

  const getData = (id) => {
    axios.get(`${REACT_APP_BASE_URL}books/${id}`, { headers }).then((res) => {
      setData(res.data.data);
    });
  };
  const updated = (bool) => {
    console.log(78778, bool);
    setUpdateChapters(bool);
  };

  const onRefresh = (pram) => {
    if (pram) {
      setPrompt(false);
    }

    setState("edit");

    setDisabled(false);
  };
  return (
    <Card>
      <Prompt
        when={prompt}
        message={(location, action) => {
          Swal.fire({
            title: "Are you sure you want to go " + location.pathname + " ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              setPrompt(false);
              history.push(location);
            }
          });
          return false;
        }}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Basic Info" key="1">
          <BasicInfo
            data={data || ""}
            getData={getData}
            id={id}
            type={type}
            status={state}
            url={url}
            headers={headers}
            onRefresh={onRefresh}
          />
        </TabPane>
        <TabPane tab="Details" disabled={disabled} key="2">
          <Details headers={headers} url={url} data={data} id={id} />
        </TabPane>
        <TabPane tab="Chapters" disabled={disabled} key="3">
          <Chapters
            headers={headers}
            updated={updated}
            url={url}
            id={id}
            data={data}
          />
        </TabPane>
        <TabPane tab="View Chapters" disabled={disabled} key="4">
          <ViewChapters
            updateChapters={updateChapters}
            updated={updated}
            headers={headers}
            url={url}
            id={id}
            data={data}
          />
        </TabPane>
        <TabPane tab="Related books" disabled={disabled} key="5">
          <RelatedBooks
            headers={headers}
            url={REACT_APP_BASE_URL}
            id={id}
            data={data}
          />
        </TabPane>
        <TabPane tab="Book notification" disabled={disabled} key="6">
          <BookNotification
            headers={headers}
            url={REACT_APP_BASE_URL}
            id={id}
            data={data}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default Modify;
