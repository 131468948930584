import React from 'react';
import {Button} from 'antd'

const SaveButton = ({loading,action,name}) =>{

    return (
        <Button 
        style={{}}
        onClick={action}
        loading={loading}
        key="submit" 
        type="primary" >
         {name}
        </Button>
    )

}

export default SaveButton;