import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Switch,
  Button,
  Alert,
  Space,
  Popconfirm,
  message,
} from "antd";
import axios from "util/Api";

import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import CK from "../../../components/editers/ck";
import DiscardButton from "../../../components/buttons/discard";

class Info extends Component {
  state = {
    title: "",
    title_ar: "",
    content: "",
    content_ar: "",
    loading: false,
    validation: false,
  };

  componentDidMount() {
    const headers = this.props.headers;
    if (this.props.type === "edit") {
      axios
        .get(`${REACT_APP_BASE_URL}pages/${this.props.id}`, { headers })
        .then((res) => {
          const data = res.data.data;

          this.setState({
            title: data.title,
            title_ar: data.title_ar,
            content: data.content,
            content_ar: data.content_ar,
          });
        })
        .catch((err) => {
          if (err.response.data.errors) {
            err.response.data.errors.map((error) =>
              message.error(error.msg, 20)
            );
          } else {
            message.error(err.response.status + "There is a problem");
          }
        });
    }
  }

  onSave = () => {
    const { title, title_ar, content, content_ar } = this.state;
    const { headers, type, id } = this.props;
    if (title === "" || title_ar === "") {
      this.setState({ validation: true });
    } else {
      this.setState({ loading: true }, () => {
        if (type === "create") {
          axios
            .post(
              `${REACT_APP_BASE_URL}pages`,
              {
                title_ar,
                title,
                content: localStorage.getItem("content") || content,
                content_ar: localStorage.getItem("content_ar") || content_ar,
              },
              { headers }
            )
            .then((res) => {
              message.success("succesfuly created");
              this.setState({ loading: false });
            })
            .catch((err) => {
              if (err.response.data.errors) {
                err.response.data.errors.map((error) =>
                  message.error(error.msg, 3)
                );
              } else {
                message.error(err.response.status + " There is a problem");
              }
              this.setState({ loading: false });
            });
        } else {
          axios
            .put(
              `${REACT_APP_BASE_URL}pages/${id}`,
              {
                title_ar,
                title,
                content: localStorage.getItem("content") || content,
                content_ar: localStorage.getItem("content_ar") || content_ar,
              },
              { headers }
            )
            .then((res) => {
              message.success("succesfuly updated");
              this.setState({ loading: false });
            })
            .catch((err) => {
              if (err.response.data.errors) {
                err.response.data.errors.map((error) =>
                  message.error(error.msg, 3)
                );
              } else {
                message.error(err.response.status + " There is a problem");
              }
              this.setState({ loading: false });
            });
        }
      });
    }
  };
  componentWillUnmount() {
    localStorage.removeItem("content");
    localStorage.removeItem("content_ar");
  }

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { title, title_ar, content, content_ar, loading, validation } =
      this.state;
    const { id } = this.props;
    return (
      <div>
        <Row>
          <Col xs={24} md={12}>
            <label>
              <h4>
                Title<span className="req-astrick">*</span>
              </h4>
              <Input
                defaultValue={title}
                value={title}
                name="title"
                onChange={this.handelChange}
              />
              {validation && !title && (
                <p style={{ color: "red" }}>This field is required!</p>
              )}
            </label>
            <br />
            <br />
          </Col>
          <Col xs={24} md={12}>
            <label>
              <h4>
                Arabic Title <span className="req-astrick">*</span>
              </h4>
              <Input
                defaultValue={title_ar}
                value={title_ar}
                name="title_ar"
                onChange={this.handelChange}
              />
              {validation && !title_ar && (
                <p style={{ color: "red" }}>This field is required!</p>
              )}
            </label>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <label>
              <h4>
                Content<span className="req-astrick">*</span>
              </h4>
              <CK id={id} name="content" data={content} height={500} />
              <br />
            </label>
          </Col>
          <Col xs={24} md={12}>
            <label>
              <h4>
                Arabic Content<span className="req-astrick">*</span>
              </h4>
              <CK id={id} name="content_ar" data={content_ar} height={500} />
              <br />
            </label>
          </Col>
        </Row>
        <br />
        <br />
        <Row justify="center">
          <Space>
            <DiscardButton endpoint="/booksManager" />
            <Button onClick={this.onSave} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        </Row>
      </div>
    );
  }
}

export default Info;
