import React, {useEffect} from "react";
import {userSignIn} from "../appRedux/actions/Auth";

import {Button, Checkbox, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import InfoView from "../components/InfoView";
import {Alert} from "antd";
const FormItem = Form.Item;


const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const error = useSelector(({common}) => common.error)

  const onFinish = values => {

    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return (
    
    <div className="gx-login-container">
    <div className="gx-login-content">
      <div className="gx-login-header gx-text-center">
        {error && <Alert message={error} type="error" showIcon/>}
        <h1 className="gx-login-title">Sign In</h1>
      </div>
      <Form
        initialValues={{ remember: true }}
        name="basic"
        onFinish={onFinish}
        className="gx-signin-form gx-form-row0">
        <FormItem rules={[{ required: true, message: 'Please input your E-mail!' }]} name="email">

            <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                   placeholder="Email"/>
        </FormItem>
        <FormItem rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">

            <Input prefix={<LockOutlined  style={{color: 'rgba(0,0,0,.25)'}}/>}
                   type="password"
                   placeholder="Password"/>
        </FormItem>

        <FormItem className="gx-text-center">
          <Button type="primary" htmlType="submit">
            Log in
          </Button>
        </FormItem>
      </Form>
    </div>
  </div>
  );
};

export default SignIn;
