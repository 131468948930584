import React, { useEffect, useState } from "react";
import { REACT_APP_BASE_URL } from "../../../../constants/ActionTypes";
import { message, Row, Col, Spin, Space, Button, DatePicker } from "antd";
import axios from "util/Api";

import { Scrollbars } from "react-custom-scrollbars";
import { EditOutlined, CheckOutlined } from "@ant-design/icons";

const ListSubscriptions = ({ headers, data, updateSubs, updated }) => {
  const [data1, setData1] = useState([]);
  const [editSub, setEditSub] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const getSubs = () => {
    axios
      .get(
        `${REACT_APP_BASE_URL}users/${data.id}/subscription/details?status=active&page=1&size=30`,
        { headers }
      )
      .then((res) => {
        if (res.data) {
          setData1(res.data.data);
          setExpireDate(res.data.data[0].expire_date);
        }
      })
      .catch((err) => message.error(err.response.data.errors[0].message));
  };

  const updateDate = (id) => {
    console.log(expireDate);
    axios
      .put(
        `${REACT_APP_BASE_URL}subscription/${id}/users`,
        { expire_date: expireDate },
        { headers }
      )
      .then(() => {
        setEditSub(false);
        getSubs();
      })
      .catch((err) => message.error(err.response.data.errors[0].msg));
  };

  useEffect(() => {
    getSubs();
  }, []);

  useEffect(() => {
    if (updateSubs) {
      getSubs();
      updated();
    }
  });

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <label>
            <h4>Provider</h4>
          </label>
        </Col>
        <Col span={8}>
          <label>
            <h4>Type</h4>
          </label>
        </Col>
        <Col span={8}>
          <label>
            <h4>Expire Date</h4>
          </label>
        </Col>
      </Row>
      {data1.length > 0 ? (
        <Scrollbars
          style={{ height: 200, width: "100%", overflowX: "hidden !important" }}
        >
          {data1.map((item, i) => (
            <Row
              align="middle"
              style={{
                padding: "20px 10px",
                backgroundColor: `${i % 2 !== 0 ? "white" : "#F5F5F5"}`,
                width: "100%",
              }}
            >
              <Col span={8}>
                <h5>{item.provider}</h5>
              </Col>
              <Col span={8}>
                <h5>{item.type}</h5>
              </Col>
              <Col span={8}>
                <Row align="top">
                  {i === 0 ? (
                    <Col>
                      {!editSub ? (
                        <Space>
                          <h5>{item.expire_date}</h5>
                          <Button
                            style={{ paddingTop: "10px" }}
                            onClick={() => setEditSub(true)}
                            type="text"
                            icon={<EditOutlined />}
                          />
                        </Space>
                      ) : (
                        <Space>
                          <DatePicker
                            onChange={(date, dateString) =>
                              setExpireDate(dateString)
                            }
                          />
                          <Button
                            style={{ paddingTop: "10px", color: "green" }}
                            onClick={() => updateDate(item.subscription_id)}
                            type="text"
                            icon={<CheckOutlined />}
                          />
                        </Space>
                      )}
                    </Col>
                  ) : (
                    <Col>
                      <Space>
                        <h5>{item.expire_date}</h5>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          ))}
        </Scrollbars>
      ) : (
        <h2 style={{ textAlign: "center", margin: "60px auto" }}>
          No subscriptions
        </h2>
      )}
    </>
  );
};

export default ListSubscriptions;
