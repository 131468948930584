import React from 'react';


const timeConverter = (time_in_ms) =>{
    if ( time_in_ms >= 31104000000){
        var numberOfYears = Math.floor( time_in_ms/31104000000);

        switch (numberOfYears) {
            case 1:
                return('1 year');
            
        
            default:
               return(numberOfYears + ' years')
        }

    }
    else{
        var numberOfmonths = Math.floor( time_in_ms/2592000000);
        switch (numberOfmonths) {
            case 1:
                return('1 month');
            
            default:
               return(numberOfmonths + ' months')
        }

    }
}

export default timeConverter;