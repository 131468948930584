import axios from "./Api";
import Swal from "sweetalert2";

const setupInterceptors = () => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "login" && err.response) {
        // Access Token was expired

        if (err.response.status >= 500 || err.response.status === 403) {
          Swal.fire({
            title: "Something went wrong ",
            text:
              err.response.data?.message + "\n Please contact IT department " ||
              "Please contact IT department",
          });
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setupInterceptors;
