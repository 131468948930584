import React,{ Component} from 'react';
import { Select } from 'antd';
import axios from 'axios';
import Item from 'antd/lib/list/Item';

const { Option } = Select;

class SearchInput extends Component {
  state={
    list:[],
    updated:0
  }
  componentDidMount(){
    const {url,headers,ids} = this.props;
    const body ={"pageSize": 1000,
    "page": 1,
    "filtered": [{id:'available',value:true}]};
    axios
    .post(`${url}books/datatable`,body,{headers})
    .then(({data})=> {
      let list = data.data.map(book =>({
        id:book.id,
        title:book.title,
        label:book.title,
        value:book.id,
        status: "pending",
        image_url:book.image_url
      }));

      if (ids) {
        const idsList = ids.map(item => item.id)
        let list2 = list.filter(item => {if(!idsList.includes(item.id)){
          return item

        }})
        this.setState({list:list2})
      }
      else {
        this.setState({list})
      }
    }).catch(err=>console.log(err.response))

  }
  onFilter = (value) => {
    const {url,headers,ids} = this.props;
    const arabic = /[\u0600-\u06FF]/;
    console.log('test',arabic.test(value))
    const body ={"pageSize": 1000,
    "page": 1,
    "filtered": [{id:arabic.test(value)?'title_ar':'title', value:value},{id:'available',value:true}]};
    axios
    .post(`${url}books/datatable`,body,{headers})
    .then(({data})=> {
      let list = data.data.map(book =>({
        id:book.id,
        title:book.title,
        label:book.title,
        value:book.id,
        status: "pending",
        image_url:book.image_url
      }));
      console.log('test',arabic.test(value))
      if (ids) {
        const idsList = ids.map(item => item.id)
        let list2 = list.filter(item => {if(!idsList.includes(item.id)){
          return item

        }})
        this.setState({list:list2})
      }
      else {
        this.setState({list})
      }
    }).catch(err=>console.log(err.response))

  }

  componentDidUpdate(){
    if(this.props.ids && (this.state.updated === 0)) {
      this.onFilter('')
      this.setState({updated:1})
    }
  }

  onChange = (value,item) => {
    this.props.addNewBook(value)
  }

  render(){
    const {list} = this.state
    return(
      <div>
         <Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select a book"
    optionFilterProp="children"
    onChange={this.onChange}
    onSearch={this.onFilter}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {list.map(item => (<Option value={item.value}>{item.title}</Option>))}


  </Select>
      </div>
      )
  }
}

export default SearchInput;
