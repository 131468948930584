// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const UPDATE_DATA_TABLE='UPDATE_DATA_TABLE';
//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const HEADER ='header';
export const USER_ID = 'user_id';
export const USER_ROLE = 'user_role';
export const USER_PERMITIONS = 'USER_PERMITIONS';

//app constants
export const REACT_APP_BASE_URL= process.env.REACT_APP_BASE_URL;

//books manger
export const CHPTERS ='chapters'

//Subscription
export const ADD_SUBSCRIBER_TAB = 'ADD_SUBSCRIBER_TAB';
export const DELETE_SUBSCRIBER_TAB = 'DELETE_SUBSCRIBER_TAB';
export const REVIEW_SUBSCRIBER_TAB = 'REVIEW_SUBSCRIBER_TAB';
export const UPDATE_SUBSCRIBER_TAB = 'UPDATE_SUBSCRIBER_TAB';

//settings


