import React, { useState, useEffect } from "react";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import Highlighter from "react-highlight-words";
import Axios from "axios";
import axios from "../../util/Api";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./style.css";
import { useAsync } from "react-async-hook";
import { userSignOut } from "../../appRedux/actions/Auth";
import { updateDataTable } from "../../appRedux/actions/Common";
import { useLocation, useParams, useHistory } from "react-router-dom";

let cancelToken;

const fetchData = async (
  state,
  dataSource,
  headers,
  filtering,
  dispatch,
  type
) => {
  state.filtered = filtering
    ? state.filtered.concat(filtering)
    : state.filtered;

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  console.log("hiii");

  cancelToken = Axios.CancelToken.source();

  var body = {
    pageSize: state.pageSize,
    page: state.page + 1,
    filtered: state.filtered,
    sorted: state.sorted,
  };
  try {
    if (type) {
      let filteraion = state.filtered.map((item) => ({
        ...item,
        key: item.id,
      }));
      body = {
        filter: filteraion,
        order: [],
      };
    }
    console.log("hjhjhhjhjhhh");
    return axios
      .post(
        `${dataSource}${
          type ? "?page=" + (state.page + 1) + "&size=" + state.pageSize : ""
        }`,
        body,
        { headers, cancelToken: cancelToken.token }
      )
      .catch((err) => {
        if (err.response.status == 401) {
          dispatch(userSignOut());
        }
      });
  } catch (error) {
    console.log(error);
  }
};

const DataTable = (props) => {
  const { endPoint, columns, pageSize, filtering, type } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();
  const [req, setReq] = useState({
    data: [],
    pages: 0,
    page: -1,
    loading: false,
    pageSize: 10,
  });
  const [filter, setFilter] = useState([]);
  const headers = useSelector(({ auth }) => auth.header);
  const dataSource = `${REACT_APP_BASE_URL}${endPoint}`;
  const { updateTable } = useSelector(({ common }) => common);
  const [dataState, setState] = useState({
    pageSize: 10,
    page: 0,
    filtered: [],
    sorted: [],
  });
  const asyncFetch = useAsync(
    fetchData,
    [dataState, endPoint, headers, filtering, dispatch, type],
    () => {
      setReq({ loading: true });
    }
  );

  useEffect(() => {
    if (location.search) {
      console.log(222, location.search, location);

      let searched = encodeURI(location.search).slice(
        1,
        location.search.length
      );
      searched = searched.split("&");
      searched = searched.map((item) => {
        return item.split("=");
      });
      const filterValue = [];
      searched.forEach((item) => {
        console.log(11, item[0]);
        if (item[0] == "page") {
          setReq({ ...req, page: Number(item[1]) });
          setState({ ...dataState, page: Number(item[1] - 1) });
        } else {
          filterValue.push({
            id: item[0],
            value: item[1],
          });
        }
      });

      if (filterValue.length > 0) {
        setFilter(filterValue);
        setState({ ...dataState, filtered: filterValue || [] });
      }
    }
  }, []);
  useEffect(() => {
    if (updateTable) {
      dispatch(updateDataTable(false));

      onFetch({
        data: [],
        pages: 0,
        page: dataState.page,
        loading: false,
        pageSize: 10,
        filtered: dataState.filtered,
      });
    }
  });

  useEffect(() => {
    if (location.search !== `?page=${dataState.page}`) {
      const newUrl = location.pathname + "?page=" + (dataState.page + 1);
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [dataState]);

  useEffect(() => {
    console.log(dataState.page + 1, location.search);

    if (filter.length > 0) {
      let str = filter.map((item) => {
        console.log(item);
        return "" + item.id + "=" + item.value;
      });
      str = str.join("&");
      const newUrl =
        location.pathname + "?page=" + (dataState.page + 1) + "&" + str;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [filter]);

  const onFetch = (state) => {
    setState(state);
  };

  const click = () => {
    onFetch(req);
  };

  return (
    <div>
      <ReactTable
        columns={columns}
        onChange={click}
        resolveData={(data) => data.map((row) => row)}
        onPageChange={(page, e) => {
          console.log(page);
        }}
        manual
        className="-highlight"
        style={{ textAlign: "center", alignSelf: "center" }}
        sortable={true}
        page={asyncFetch.result ? asyncFetch.result.data.page : dataState.page}
        data={asyncFetch.result ? asyncFetch.result.data.data : []}
        pages={
          asyncFetch.result
            ? asyncFetch.result.data.total ||
              asyncFetch.result.data.num_of_pages
            : 0
        } // Display the total number of pages
        loading={asyncFetch.loading} // Display the loading overlay when we need it
        onFetchData={onFetch} // Request new data when things change
        filterable={true}
        keyField="id"
        onFilteredChange={(filtered, column) => {
          if (props.export) {
            let arr = filtered.map((item) => {
              return { key: item.id, value: item.value };
            });
            props.export(arr);
          }
          setFilter(filtered);
        }}
        defaultPageSize={10}
        headerStyle={{ color: "red" }}
      />
    </div>
  );
};

export default DataTable;
