import React from 'react';
import { Modal, Button,Result,Spin } from 'antd';
import {REACT_APP_BASE_URL} from "../../constants/ActionTypes";
import SortableComponent from '../lists/AuthorsRoleOrdering';

const AuthorRoleModal = ({headers,id,action,visible,updated}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const url = `${REACT_APP_BASE_URL}books/${id}`

  const handleOk = () => {

    setConfirmLoading(true);
    setTimeout(() => {

      setConfirmLoading(false);
      action();
    }, 2000);
  };

  const handleCancel = () => {
    action();
  };

  return (
    <>
      <div style={{zIndex:1,backgroundColor:'black', position: 'relative',height:'200%',width:'200%'}}></div>
      <Modal
        title="Book Authors"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >

      <SortableComponent updated={updated} headers={headers} url={url}/>
      </Modal>
    </>
  );
};

export default AuthorRoleModal;
