import React from 'react';
import {Link,useHistory} from 'react-router-dom';
import {Button} from 'antd';



const DiscardButton = ({endpoint}) => {
  const history = useHistory()

  return (
    <div>
      <Button  danger onClick={()=> {history.push(endpoint) }}>
      Discard
    </Button>
    </div>
  )
}

export default DiscardButton;
