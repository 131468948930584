import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  REACT_APP_BASE_URL,
  HEADER,
  USER_ID,
  USER_ROLE,
  USER_PERMITIONS,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { message } from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        REACT_APP_BASE_URL + "admins/auth/login/email",
        {
          email: email,
          password: password,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async ({ data }) => {
        const headers = {
          "Content-Type": "application/json",
          platform: "admin-panel",
          Authorization: "Bearer " + data.data.token,
        };
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user_id", data.data.user.id);
        localStorage.setItem("user", data.data.user.name);
        localStorage.setItem("user_role", data.data.user.role);
        localStorage.setItem("Authorization", "Bearer " + data.data.token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + data.data.token;
        await dispatch(getPermitions(data.data.user.id, headers));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.token });
        dispatch({ type: USER_ID, payload: data.data.user.id });
        dispatch({ type: USER_ROLE, payload: data.data.user.role });
        dispatch({ type: USER_DATA, payload: data.data.user.name });
        dispatch({ type: HEADER, payload: headers });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: "INVALID EMAIL OR PASSWORD" });
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const user_id = localStorage.getItem("user_id");

    axios
      .post(REACT_APP_BASE_URL + "clients/" + user_id)
      .then((data) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.data });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.msg });
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("token");
    localStorage.removeItem("user_permitions");
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};

export const updateProfile = (values, headers) => {
  return (dispatch) => {
    axios
      .put(`${REACT_APP_BASE_URL}admins/update`, values, { headers })
      .then(() => {
        localStorage.setItem("user", values.name);
        dispatch({ type: USER_DATA, payload: values.name });
        message.success("successfully updated");
      })
      .catch((err) => console.log(err.response));
  };
};

export const getPermitions = (id, headers) => {
  return (dispatch) => {
    axios
      .get(`${REACT_APP_BASE_URL}users/${id}/permissions`, { headers })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          localStorage.setItem(
            "user_permitions",
            JSON.stringify(res.data.data)
          );
          dispatch({ type: USER_PERMITIONS, payload: res.data.data });
        } else {
          localStorage.setItem("user_permitions", JSON.stringify("all"));
          dispatch({ type: USER_PERMITIONS, payload: "all" });
        }
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => userSignOut());
  };
};
