import React, { Component, useState } from "react";
import { Col, Row, Divider } from "antd";
import ChapterQuestionModal from "../modal/chapterQuestionModal";

import axios from "axios";
import Cell from "./Cell";
import { arrayMove, SortableContainer } from "react-sortable-hoc";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import { useDispatch } from "react-redux";

const Container = SortableContainer(
  ({ items, open, url, id, headers, action, title, loading, bookType }) => {
    const [qustionModalState, setQustionModalState] = useState({
      visible: false,
      id: null,
    });
    return (
      <>
        <Row>
          <Col span={24}>
            {items.map((item, index) => (
              <Cell
                addQusetionAction={(visible, id) =>
                  setQustionModalState({ visible, id })
                }
                bookType={bookType}
                loading={loading}
                key={item.id}
                id={item.id}
                index={index}
                i={index}
                item={item}
                open={open}
                book_id={id}
                url={url}
                headers={headers}
                action={action}
                title={title}
              />
            ))}
          </Col>
        </Row>
        {qustionModalState.visible && (
          <ChapterQuestionModal
            id={qustionModalState.id}
            book_id={id}
            action={() => setQustionModalState({ visible: false, id: null })}
            visible={qustionModalState.visible}
          />
        )}
      </>
    );
  }
);

class DragNDrop extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { headers, id } = this.props;

    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex),
      },
      () => {
        const body = this.state.items.map((item, i) => ({
          id: item.id,
          order: i,
        }));
        axios
          .put(
            `${REACT_APP_BASE_URL}books/${id}/chapters/order`,
            { chapters: body },
            { headers }
          )
          .then((res) => {
            console.log("updated", 9999);
            this.props.updated(true);
          })
          .catch((err) => console.log(err.response));
      }
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
    };
  }

  componentDidMount() {
    const { url, headers, id } = this.props;
    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState({ items: res.data.data });
        }
      })
      .catch((err) => console.log(err.response));
  }
  componentDidUpdate() {
    if (this.props.update) {
      const { url, headers, id } = this.props;
      axios
        .get(`${url}/${id}/chapters`, { headers })
        .then((res) => {
          if (res.data) {
            this.setState({ items: res.data.data });
          }
        })
        .catch((err) => console.log(err.response));
    }
  }
  action = () => {
    const { url, headers, id, showBtns } = this.props;
    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          showBtns(true);
          this.setState({ items: res.data.data });
        } else {
          showBtns(false);
          this.setState({ items: [] });
        }
      })
      .catch((err) => console.log(err.response));
  };

  render() {
    const { items } = this.state;
    const { headers, id, url, data, bookType } = this.props;
    const title = data ? data.title : "";

    return (
      <div style={{ direction: "rtl" }} className="gx-main-content gx-mb-4">
        <Container
          bookType={bookType}
          items={items}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          open={this.props.open}
          headers={headers}
          id={id}
          url={url}
          action={this.action}
          title={title}
          loading={this.props.loading}
        />
      </div>
    );
  }
}

export default DragNDrop;
