import React, {Component} from 'react';
import { Modal, Button,Row,Col,Input,Radio,Switch,Spin,message ,Select} from 'antd';
import UploadAudio from '../buttons/uploadAudio';
import UploadImg from '../Images/uploadimg';
import axios from 'axios';
import {REACT_APP_BASE_URL} from "../../constants/ActionTypes";
import CK from '../editers/ck';
import Selector from '../inputs/Selector';
const { Option } = Select;


const uploadRef = React.createRef();

class VoiceOversModal extends Component {
  state = {
    name:'',
    loading:'',
    showVld:''
  }

  componentDidMount() {
    const {data} = this.props

    if(data) {
      this.setState({
        name:data.name
      })
    }
  }

  handleChange = (e) => {
    e.preventDefault();

    this.setState({[e.target.name]:e.target.value});

  }

 

  submit = ()=> {
    this.setState({loading:true});
    const {headers, status,data} = this.props;
    const body = this.state;

    if((  this.state.name==='') ) {
      this.setState({showVld:true},()=>this.setState({loading:false}))
    }
    else {
      this.setState({'msg':''})
      if (status === 'create'){
        axios
        .post(`${REACT_APP_BASE_URL}voice_overs`,body,{headers})
        .then((res)=>{
          localStorage.setItem('updated', 'true')
          this.props.action(res.data.data)
          this.props.updated();

        })
        .catch(err=>{
          this.setState({loading:false})
          message.error(err.response.data.errors[0].msg)})
        
      }
      else {
        axios
        .put(`${REACT_APP_BASE_URL}voice_overs/${data.id}`,body,{headers})
        .then(()=>{
          this.props.action()
         this.props.updated();
        })
        .catch(err=>{
          this.setState({loading:false})
          message.error(err.response.data.errors[0].msg)})
      }

    }
  }

  

  render() {
    const {name,showVld,loading} = this.state;
    const { status,data,headers} = this.props;
    return (
        <Modal
        title={this.props.title}
        centered
        confirmLoading={loading}
        visible={this.props.visible}
        onOk={this.submit}
        onCancel={() => this.props.action()}
        
      >

      <Row>
        <Col span={24} >
          <label>
              <h4>Name</h4>
          <Input defaultValue={name} placeholder='name' value={name} name='name' onChange={this.handleChange} />
          <br/>
          {showVld && !name && <p className='error'>name is required!</p>}

          </label>
        </Col>
        
      </Row>
      
      </Modal>
    )
  }
}


export default VoiceOversModal;
