import React,{useState} from 'react';
import CreateSubOrGiftFrom   from "../createSubOrGiftForm";


const CreateSubscription = ({data,headers,updated}) =>{

    
    return(
        <>
        <CreateSubOrGiftFrom updated={updated} data={data} headers={headers} type={'subs'} endPoint={'subscribe/client'}/>
        </>
    )

}

export default CreateSubscription;