import React, { Component } from "react";
import Selector from "../../../components/inputs/Selector";
import CK from "../../../components/editers/ck";
import axios from "util/Api";

import DiscardButton from "../../../components/buttons/discard";
import { PlusOutlined, TeamOutlined } from "@ant-design/icons";
import CatModal from "../../../components/modal/catModal";
import AuthorsModal from "../../../components/modal/AuthorsModal";
import AuthorRoleModal from "../../../components/modal/AuthRoleModal";
import { Row, Col, Button, Tooltip, Space, message } from "antd";
import TagsSlectore from "../../../components/inputs/tagsSelector";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { dateFnsLocalizer } from "react-big-calendar";
const doc = new GoogleSpreadsheet(
  "1fLEkg3KlDKh7_dDbM-A3g6MV68R4Mdk6vPD5mk4b5zk"
);

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target_audience: "",
      short_desc: "",
      about_book: "",
      about_book_authors: "",
      msg: "",
      color: "",
      loading: false,
      authVisi: false,
      catVisi: false,
      roleVisi: false,
      disabled: false,
      update: false,
    };
  }
  async componentDidMount() {
    const { data } = this.props;
    // 'Larry Page'

    if (data) {
      this.setState({
        target_audience: data.book_extras
          ? data.book_extras.target_audience
          : data.target_audience,
        about_book_authors: data.book_extras
          ? data.book_extras.about_book_authors
          : data.about_book_authors,
        short_desc: data.short_desc,
        about_book: data.book_extras
          ? data.book_extras.about_book
          : data.about_book,
        disabled: data.authors_names ? false : true,
      });
    }
  }
  catAction = () => {
    this.setState({ catVisi: !this.state.catVisi });
  };

  updated = () => {
    this.setState({ update: !this.state.update });
  };

  onChange = (name, val) => {
    this.setState({ [name]: val });
  };
  authAction = () => {
    this.setState({ authVisi: !this.state.authVisi });
  };
  disabledAction = (param) => {
    if (param > 0) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  };
  roleAction = () => {
    this.setState({ roleVisi: !this.state.roleVisi });
  };

  onSubmit = () => {
    const { headers, url, id, data } = this.props;
    const {
      target_audience,
      about_book,
      short_desc,
      about_book_authors,
      disabled,
    } = this.state;

    const body = {
      target_audience: target_audience,
      about_book: about_book,
      short_desc: short_desc,
      about_book_authors: disabled ? null : about_book_authors,
    };
    this.setState({ loading: true }, () => {
      axios
        .put(`${url}/${id}`, body, { headers })
        .catch((err) => {
          message.error(err.response.data.errors[0].msg);
          this.setState({ color: "error", loading: false });
        })
        .then(async () => {
          await doc.useServiceAccountAuth({
            // env var values are copied from service account credentials generated by google
            // see "Authentication" section in docs for more info
            client_email: "wajeez@wajeez-312209.iam.gserviceaccount.com",
            private_key:
              "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCaipVwbt37hU93\n/Pi3+p/s61ahmhLVtIS9iZkL+Bt/oSHWPV2CH8jMIJgKFyb1sQ5Rt+4SmdflxGB/\n+pzVlCbtiLhw+9xjRT04LvVT4mKD2j7wcFdZMsu5gwMgyV6zY/qG61yUMoOEPvOc\nepJiV/jbc9u5em3CZfKgBkPG/MGriuR7FB1YNAuOlXP5QURFSlVPeKQElEJ4tyGv\nQtRlUCOSd/JMBpAyT0B/GzSx/Rnkfn9Ru52emt9wizuvbUx5rdR7dKr115s5ZFcW\ndBDoPAC+TvfmiyN1Uy7/efg2Cwr+EVRY6wqS6OAlyCFhM9BKy3QoWeNaeoUrlpsC\nLA8Gqrw1AgMBAAECggEAMVndJBT7hYVKXe5mfNYnuRBkT2H07tLhauekfLqECYtq\n01Awasv8oxcrhlljYyViBb2fZMhFNkYybNwatmfD0KoPAGGlvPvQ836ncFzEigep\nfr3ZXw5GGZ1ZY+B6gf4E9I33uAmzjwlNYWSRF5+xRF9oxP/wShqlUu39Oe/gZweJ\n0kITS6JP2BOcXOji4l5/GaXMZjcpBVkgdTfxqHJZEa5i1UZB1sDOC9rgRMaTSzvH\nbWkFyCzm46tpcCxQk5zWIt+9rGl+ygZXm6QiJmbYUfdsn9ASEP+tvRQJxNc+Uilp\nAp8cCLJpPefFe/Bf9bAURRtdRXAxbtB4cPRjid4e9wKBgQDWIAe51JU9BAUTaM4t\ni3NvU70jQBmmM6ef1ILbLsQaji7D/vkRHfGcQBwygeenGrJh6HvamkISXee0xYeP\niioVubcnsVkqpHfcxFQ86tOhy9v/sQcG3TOesaPI2r5UtjK2AFSOMUei6CLZgxCl\nMEjV+9zf56vcuEYze4qHA3gi6wKBgQC4w4x26vfegWvHiLWu12yetHm2Qiars1ND\nauHYgwc+O3QKw8OuJg065Rv8JK9zMbuX624YP3yoo3g0o60CLSpkfk5b30PQmolj\nzsqKytvHO/3jgNbO9lN0oC+gzhFBfVVY+J6GzjV+SZDPxroQqb3mwDU7EgI/oh8Z\nhcbdI4mVXwKBgGHZv04XrJ92f89P3LCJgpYEYXGDcHU4M8jhd28sXud3xD1C/pTo\n5ymtTnVyUedWVuBROpA6YCk+io1cm0doLzVAakeUTxgikrfrbEduVIoivAWL45cq\nbNV/fkZiWAokPOZZHkrgkEkM5igaMfgbKZ3H/DbCCxLRjxslwLdUSYlDAoGAEXwf\n/Nj7oAjrZDShzOwCEXrO0dqswi70QDsLKGwt8Uu7DZG9IOvk8J/h6BioqeQY9qWH\nA7BuYZ8mrwSWSNBxZJU3YSZdyo31VoapUD0mU1JwEmmM6oURHMZT+4nExEfSHdtZ\nVTSKh2r6007hKNaHbCDgaLipkdAL1WR9j0vfp00CgYEAkQwgusRD0PmQ0owGrq70\n4fj5EzADPjjAHtRdFsEMybto3QnetXUbtsSqx404sbCYdiGEtXseZnMvAd0zen49\nemCb1sJTHYuVp+S9/Q4sdG2ugVERYVdOiZkX/G5BRG2PohCM88t9Z884Sl72M/OQ\nReThpK9W8JZtlPFqCDtuJ7E=\n-----END PRIVATE KEY-----\n",
          });

          await doc.loadInfo(); // loads document properties and worksheets
          console.log(doc.title);
          const date = new Date();
          const sheet = await doc.addSheet({
            headerValues: [
              "id",
              "title",
              "target_audience",
              "about_book",
              "short_desc",
              "about_book_authors",
            ],
          });

          // append rows
          const larryRow = await sheet.addRow({
            ...body,
            id,
            title: data.title,
          });
          sheet.updateProperties({ title: date.toISOString() });
        })
        .then(() => {
          this.setState({ color: "success", loading: false }, () => {
            message.success("successfuly updated");
          });
        })
        .catch((err) => {
          // message.error(err.response.data.errors[0].msg);
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { headers, url, id } = this.props;
    const {
      short_desc,
      disabled,
      target_audience,
      about_book,
      loading,
      catVisi,
      authVisi,
      roleVisi,
      about_book_authors,
      update,
    } = this.state;
    return (
      <div style={{ margin: "10px " }}>
        {this.props.data.type=="quran" ? <Row>

          <Col xs={24} md={12}>
            <label>
              <h4>Tags</h4>
              <Row justify="start">
                <Col span={24}>
                  <TagsSlectore relation="book" id={id} headers={headers} />
                  {/* <Selector  id={id} headers={headers} url={url} endpoint={'categories'} /> */}
                </Col>
                {/* <Col span={4} style={{alignSelf:'center',textAlign: 'end'}}>
                <Tooltip placement="top" title={'Add new category'}>
                <PlusOutlined className='icon-btns' onClick={this.catAction}  style={{cursor:'pointer',fontSize:'24px'}}/>
                </Tooltip>
                </Col> */}
              </Row>
            </label>
          </Col>
        </Row> : <>
          <Row>
            <Col xs={24} md={12}>
              <label>
                <h4>Authors</h4>
                <Row justify="space-between">
                  <Col span={disabled ? 21 : 20}>
                    <Selector
                      update={update}
                      updated={this.updated}
                      id={id}
                      headers={headers}
                      url={url}
                      endpoint={"authors"}
                      action={this.disabledAction}
                    />
                  </Col>
                  {!disabled ? (
                    <Col
                      span={4}
                      style={{
                        alignSelf: "center",
                        float: "right",
                        textAlign: "end",
                      }}
                    >
                      <Space>
                        <Tooltip placement="top" title={"Authors list"}>
                          <TeamOutlined
                            disabled={!disabled}
                            className="icon-btns"
                            onClick={this.roleAction}
                            style={{
                              cursor: "pointer",
                              marginRight: "5px",
                              fontSize: "24px",
                            }}
                          />
                        </Tooltip>
                        <Tooltip placement="top" title={"Add new author"}>
                          <PlusOutlined
                            className="icon-btns"
                            onClick={this.authAction}
                            style={{ cursor: "pointer", fontSize: "24px" }}
                          />
                        </Tooltip>
                      </Space>
                    </Col>
                  ) : (
                    <Col
                      span={3}
                      style={{
                        alignSelf: "center",
                        float: "right",
                        textAlign: "end",
                      }}
                    >
                      <Tooltip placement="top" title={"Add new author"}>
                        <PlusOutlined
                          className="icon-btns"
                          onClick={this.authAction}
                          style={{ cursor: "pointer", fontSize: "24px" }}
                        />
                      </Tooltip>
                    </Col>
                  )}
                </Row>
              </label>
            </Col>
            <Col xs={24} md={12}>
              <label>
                <h4>Tags</h4>
                <Row justify="start">
                  <Col span={24}>
                    <TagsSlectore relation="book" id={id} headers={headers} />
                    {/* <Selector  id={id} headers={headers} url={url} endpoint={'categories'} /> */}
                  </Col>
                  {/* <Col span={4} style={{alignSelf:'center',textAlign: 'end'}}>
                <Tooltip placement="top" title={'Add new category'}>
                <PlusOutlined className='icon-btns' onClick={this.catAction}  style={{cursor:'pointer',fontSize:'24px'}}/>
                </Tooltip>
                </Col> */}
                </Row>
              </label>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={24} md={12}>
              <label>
                <h4>About Book</h4>
                <CK
                  action={this.onChange}
                  id={id}
                  name="about_book"
                  data={about_book}
                />
              </label>
            </Col>
            <Col xs={24} md={12}>
              <label>
                <h4>Short Descripthion</h4>
                <CK
                  action={this.onChange}
                  id={id}
                  name="short_desc"
                  data={short_desc}
                />
              </label>
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs={24} md={disabled ? 24 : 12}>
              <label>
                <h4>Target Audience</h4>
                <CK
                  action={this.onChange}
                  id={id}
                  name="target_audience"
                  data={target_audience}
                />
                <br />
              </label>
            </Col>
            {!disabled && (
              <Col xs={24} md={12}>
                <label>
                  <h4>About Book Authors</h4>
                  <CK
                    action={this.onChange}
                    id={id}
                    name="about_book_authors"
                    data={about_book_authors}
                  />
                  <br />
                </label>
              </Col>
            )}
          </Row>
        </>}
        <br />
        <br />
        <Row justify="center">
          <Space>
            <DiscardButton endpoint="/booksManager" />
            <Button type="primary" onClick={this.onSubmit} loading={loading}>
              Save
            </Button>
          </Space>
        </Row>
        {catVisi && (
          <CatModal
            visible={catVisi}
            headers={headers}
            status={"create"}
            data=""
            action={this.catAction}
          />
        )}
        {authVisi && (
          <AuthorsModal
            visible={authVisi}
            headers={headers}
            status={"create"}
            data=""
            action={this.authAction}
          />
        )}
        {roleVisi && (
          <AuthorRoleModal
            updated={this.updated}
            action={this.roleAction}
            headers={headers}
            visible={roleVisi}
            id={id}
          />
        )}
      </div>
    );
  }
}

export default Details;
