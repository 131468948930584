import React from "react";
import { Avatar, Row, Divider, Popconfirm, Tooltip } from "antd";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import axios from "axios";
import { FaQuestion } from "react-icons/fa";
const DragHandle = SortableHandle(({ i }) => (
  <span style={{ fontSize: 25, cursor: "pointer", alignSelf: "center" }}>
    {/* <i className="icon icon-expand" style={{fontSize: 25}}/> */}
    {i + 1 + "."}
  </span>
));

const EndHandle = SortableHandle(() => (
  <span className="gx-draggable-icon gx-pt-2" style={{ cursor: "pointer" }}>
    <i className="icon icon-expand" style={{ fontSize: 25 }} />
  </span>
));

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibale: false,
    };
  }

  handleprevModal = () => {
    this.setState({ visibale: !this.state.visibale });
  };

  onDelete = () => {
    const { url, book_id, id, headers, action } = this.props;

    axios
      .delete(`${url}/${book_id}/chapters/${id}`, { headers })
      .then(() => action())
      .catch((err) => console.log(err.response));
  };

  onDownload = () => {
    const { url, headers, book_id, id, title, item, loading } = this.props;
    loading();
    axios
      .request({
        url: `${url}/${book_id}/download/chapters/${id}/audio`,
        method: "GET",
        headers,
        responseType: "blob", //important
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", title + "-" + item.title + ".mp3"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .then(() => loading());
  };

  render() {
    const { item, i, bookType, addQusetionAction } = this.props;
    console.log("bookType", bookType);
    const { title, id, audio_url, starred } = item;
    return (
      <Row
        style={{
          backgroundColor: `${(i + 1) % 2 === 0 ? "white" : "#F0F0F0"}`,
          height: "60px",
          padding: "5px",
          direction: "rtl",
        }}
      >
        <DragHandle i={i} />

        <div
          className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2 "
          style={{ alignSelf: "center", fontSize: "18px" }}
        >
          {title}
        </div>
        <div
          className="gx-dragndrop-actions gx-px-2"
          style={{ alignSelf: "center" }}
        >
          {bookType === "contest_program" && (
            <Tooltip title="Contest Question">
              <FaQuestion
                onClick={() => addQusetionAction(true, id)}
                style={{ fontSize: "26px", cursor: "pointer" }}
                className=" icon icon-btns"
              />
            </Tooltip>
          )}
          <Popconfirm
            title="Are you sure you want to delete this ?"
            onConfirm={this.onDelete}
            okText="Yes"
            cancelText="No"
          >
            <i
              className="gx-icon-btn icon icon-trash icon-btns"
              style={{ fontSize: 25, cursor: "pointer" }}
            />
          </Popconfirm>
          <i
            className="gx-icon-btn icon icon-edit icon-btns"
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("chapterId", id);
              localStorage.setItem("chapterState", "edit");
              this.props.open("chapter");
            }}
          />
          {audio_url && (
            <i
              className="gx-icon-btn icon icon-long-arrow-down icon-btns"
              style={{ fontSize: 25, cursor: "pointer" }}
              onClick={this.onDownload}
            />
          )}
          <i
            className="gx-icon-btn icon icon-eye icon-btns"
            style={{ fontSize: 26, cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("chapterId", id);
              this.props.open("prev");
            }}
          />

          <EndHandle />
        </div>
      </Row>
    );
  }
}

export default SortableElement(Cell);
