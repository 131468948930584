import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  DatePicker,
  Input,
  Switch,
  Row,
  Col,
  Space,
  Button,
  message,
} from "antd";
import CK from "../../../components/editers/ck";
import axios from "util/Api";

import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import disabledDate from "../../../util/disaplePrevDates";
import DiscardButton from "../../../components/buttons/discard";

const BookNotification = ({
  data,
  id,
  headers,
  showActionBtns,
  submited,
  setSubmit,
  action,
  isModal,
}) => {
  const [state, setState] = useState({
    title: "",
    description: "",
    notify: false,
    notification_date: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}books/${id}/notification/info`, { headers })
      .then((res) => {
        if (res.data) {
          const resData = res.data.data;
          setState({
            title: resData.title || data.title,
            description: resData.description || data.short_desc,
            notify: resData.notify || false,
            notification_date: resData.notification_date || null,
          });
        }
      });
  }, []);

  useEffect(() => {
    if (submited) {
      setSubmit(false);
      onSubmit();
    }
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSelect = (name) => (val) => {
    setState({ ...state, [name]: val });
  };

  const onSubmit = () => {
    setLoading(true);
    axios
      .post(`${REACT_APP_BASE_URL}books/${id}/notification/info`, state, {
        headers,
      })
      .then(() => {
        setLoading(false);
        message.success("Successfuly updated");
        if (action) {
          action();
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          err.response.data.errors.map((error) => message.error(error.msg, 5));
        } else {
          message.error(err.response.status + " There is a problem");
        }
      });
  };

  return (
    <div>
      <Row>
        <Col sm={18} md={isModal ? 18 : 12}>
          <label>
            <h4>Title</h4>
            <Input name="title" value={state.title} onChange={onChange} />
          </label>
        </Col>
        <Col sm={6} md={isModal ? 6 : 12}>
          <label>
            <h4>Notify</h4>
            <Switch
              name="notify"
              checked={state.notify}
              onChange={onSelect("notify")}
            />
          </label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={18} md={isModal ? 24 : 12} order={isModal ? 2 : 1}>
          <label>
            <h4>Description</h4>
            <CK
              name="description"
              data={state.description}
              action={(name, val) => setState({ ...state, [name]: val })}
            />
          </label>
        </Col>

        <Col sm={6} md={isModal ? 24 : 12} order={isModal ? 1 : 2}>
          <label>
            <h4> Notification Date </h4>
            <DatePicker
              disabledDate={disabledDate}
              showTime={true}
              value={
                state.notification_date
                  ? moment(state.notification_date, "YYYY-MM-DD HH:mm")
                  : ""
              }
              onChange={(date, dateString) =>
                setState({ ...state, notification_date: dateString })
              }
            />
          </label>
          {isModal && <br />}
        </Col>
      </Row>
      {showActionBtns && (
        <>
          <br />
          <br />
          <Row justify="center">
            <Space>
              <DiscardButton endpoint="/booksManager" />
              <Button onClick={onSubmit} type="primary" loading={loading}>
                Save
              </Button>
            </Space>
          </Row>
        </>
      )}
    </div>
  );
};

export default BookNotification;
