import {CHPTERS} from "../../constants/ActionTypes";

const INIT_STATE = {
  chapters: [],

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case CHPTERS: {
      return {...state, chapters: action.payload};
    }



    default:
      return state;
  }
}
