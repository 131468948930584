import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import items from "./menueItems";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { RiCustomerService2Line, RiMoonFill } from "react-icons/ri";
import { ImBooks } from "react-icons/im";
import { TiGroup } from "react-icons/ti";
import { AiFillMessage } from "react-icons/ai";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const { SubMenu } = Menu;

const SidebarContent = (props) => {
  const history = useHistory();
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const { user_role, permitions } = useSelector(({ auth }) => auth);
  const [collapsed, setCollapsed] = useState(false);
  const [showSubMenus, setShowSubMenus] = useState({
    books: false,
    users: false,
    customer: false,
    inapp: false,
    ramadan: false,
  });
  useEffect(() => {
    if (
      Array.isArray(permitions) &&
      !permitions.includes("datatable.books") &&
      history.location.pathname === "/booksManager/standard"
    ) {
      let arr = items.filter((item) => {
        let priv;
        permitions.forEach((role) => {
          if (item.key === role.name) {
            priv = item;
          }
        });
        return priv && priv;
      });
      // console.log(3434,arr,permitions.includes(items[14].key),permitions)
      // history.push('/'+arr[0].link)
    }
  }, []);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>

        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            inlineCollapsed={collapsed}
            style={{ height: "100% !important" }}
            mode="inline"
          >
            <SubMenu
              style={{ display: `${showSubMenus.books ? "block" : "none"}` }}
              key="sub1"
              icon={<ImBooks className="icon" />}
              title="Books"
            >
              {items.map((item) => {
                if (Array.isArray(permitions)) {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (item.key === role.name && item.subMenu === "books") {
                      priv = true;
                      !showSubMenus.books &&
                        setShowSubMenus({ ...showSubMenus, books: true });
                    }
                  });
                  if (priv && item.subMenu === "books") {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                } else {
                  if (item.subMenu === "books") {
                    !showSubMenus.books &&
                      setShowSubMenus({ ...showSubMenus, books: true });
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              })}
            </SubMenu>

            {((Array.isArray(permitions) &&
              permitions.find((item) => item.name === "ramadan")) ||
              permitions === "all") && (
              <SubMenu
                style={{
                  display: `${showSubMenus.ramadan ? "block" : "none"}`,
                }}
                key="sub5"
                icon={<RiMoonFill className="icon" />}
                title="Ramadan"
              >
                {items.map((item) => {
                  if (Array.isArray(permitions)) {
                    let priv = false;

                    permitions.forEach((role) => {
                      if (
                        item.key === role.name &&
                        item.subMenu === "ramadan"
                      ) {
                        priv = true;
                        !showSubMenus.ramadan &&
                          setShowSubMenus({ ...showSubMenus, ramadan: true });
                      }
                    });
                    if (priv && item.subMenu === "ramadan") {
                      return (
                        <Menu.Item key={item.link} icon={item.icon}>
                          <a href={"/" + item.link}>
                            <IntlMessages id={item.title} />
                          </a>
                        </Menu.Item>
                      );
                    }
                  } else {
                    if (item.subMenu === "ramadan") {
                      !showSubMenus.ramadan &&
                        setShowSubMenus({ ...showSubMenus, ramadan: true });
                      return (
                        <Menu.Item key={item.link} icon={item.icon}>
                          <a href={"/" + item.link}>
                            <IntlMessages id={item.title} />
                          </a>
                        </Menu.Item>
                      );
                    }
                  }
                })}
              </SubMenu>
            )}
            {items.map((item, i) => {
              if (i !== items.length - 1 && i !== items.length - 2) {
                if (permitions === "all") {
                  return (
                    <>
                      {!item.subMenu && (
                        <Menu.Item key={item.link} icon={item.icon}>
                          <a href={"/" + item.link}>
                            <IntlMessages id={item.title} />
                          </a>
                        </Menu.Item>
                      )}{" "}
                    </>
                  );
                } else {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (item.key === role.name) {
                      priv = true;
                    }
                  });
                  if (priv && !item.subMenu) {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              }
            })}
            <SubMenu
              style={{ display: `${showSubMenus.inapp ? "block" : "none"}` }}
              key="sub4"
              title="In App Message"
              icon={<AiFillMessage className="icon icon-dasbhoard" />}
            >
              {items.map((item) => {
                if (Array.isArray(permitions)) {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (item.key === role.name && item.subMenu === "inapp") {
                      priv = true;

                      !showSubMenus.inapp &&
                        setShowSubMenus({ ...showSubMenus, inapp: true });
                    }
                  });
                  if (priv && item.subMenu === "inapp") {
                    return (
                      <Menu.Item
                        className="sub-menu-item"
                        key={item.link}
                        icon={item.icon}
                      >
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                } else {
                  if (item.subMenu === "inapp") {
                    !showSubMenus.inapp &&
                      setShowSubMenus({ ...showSubMenus, inapp: true });
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              })}
            </SubMenu>
            <SubMenu
              style={{ display: `${showSubMenus.users ? "block" : "none"}` }}
              key="sub2"
              icon={<TiGroup className="icon" />}
              title="Users"
            >
              {items.map((item) => {
                if (Array.isArray(permitions)) {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (item.key === role.name && item.subMenu === "users") {
                      priv = true;
                      !showSubMenus.users &&
                        setShowSubMenus({ ...showSubMenus, users: true });
                    }
                  });
                  if (priv && item.subMenu === "users") {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>{item.title}</a>
                      </Menu.Item>
                    );
                  }
                } else {
                  if (item.subMenu === "users") {
                    !showSubMenus.users &&
                      setShowSubMenus({ ...showSubMenus, users: true });
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              })}
            </SubMenu>
            <SubMenu
              style={{ display: `${showSubMenus.customer ? "block" : "none"}` }}
              key="sub3"
              title="Customers"
              icon={<RiCustomerService2Line className="icon icon-dasbhoard" />}
            >
              {items.map((item) => {
                if (Array.isArray(permitions)) {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (
                      item.key === role.name &&
                      item.subMenu === "customers"
                    ) {
                      priv = true;
                      !showSubMenus.customer &&
                        setShowSubMenus({ ...showSubMenus, customer: true });
                    }
                  });
                  if (priv && item.subMenu === "customers") {
                    return (
                      <Menu.Item
                        className="sub-menu-item"
                        key={item.link}
                        icon={item.icon}
                      >
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                } else {
                  if (item.subMenu === "customers") {
                    !showSubMenus.customer &&
                      setShowSubMenus({ ...showSubMenus, customer: true });
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              })}
            </SubMenu>
            {items.map((item, i) => {
              if (i == items.length - 1 || i == items.length - 2) {
                if (permitions === "all") {
                  return (
                    <>
                      {!item.subMenu && (
                        <Menu.Item key={item.link} icon={item.icon}>
                          <a href={"/" + item.link}>
                            <IntlMessages id={item.title} />
                          </a>
                        </Menu.Item>
                      )}{" "}
                    </>
                  );
                } else {
                  let priv = false;

                  permitions.forEach((role) => {
                    if (item.key === role.name) {
                      priv = true;
                    }
                  });
                  if (priv && !item.subMenu) {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        <a href={"/" + item.link}>
                          <IntlMessages id={item.title} />
                        </a>
                      </Menu.Item>
                    );
                  }
                }
              }
            })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
