import React,{ Component} from 'react';
import { Select } from 'antd';
import axios from 'axios';
import {REACT_APP_BASE_URL} from '../../constants/ActionTypes'

const { Option } = Select;

class SearchInput extends Component {
  state={
    list:[],
    updated:0
  }
  componentDidMount(){
    this.getList([])

  }

  getList = (value)=>{
    const {url,headers,ids,label,filter} = this.props;
    const body ={"pageSize": 1000,
    "page": 1,
    "filtered":filter.concat(value)};
    if(this.props.type === 'list'){
      axios
      .get(`${REACT_APP_BASE_URL}${url}`,{headers})
      .then(({data})=> {
        let list = data.data.map(item =>({
          ...item,
          label:item[label],
          value:item.id,
        }));
  
        if (ids) {
          const idsList = ids.map(item => item.id)
          let list2 = list.filter(item => {if(!idsList.includes(item.id)){
            return item
          }})
          this.setState({list:list2})
        }
        else {
          this.setState({list})
        }
      }).catch(err=>console.log(err.response))
    }
    else axios
    .post(`${REACT_APP_BASE_URL}${url}`,body,{headers})
    .then(({data})=> {
      let list = data.data.map(item =>({
        ...item,
        label:item[label],
        value:item.id,
      }));

      if (ids) {
        const idsList = ids.map(item => item.id)
        let list2 = list.filter(item => {if(!idsList.includes(item.id)){
          return item
        }})
        this.setState({list:list2})
      }
      else {
        this.setState({list})
      }
    }).catch(err=>console.log(err.response))

  }
  onFilter = (value) => {
    const {label} = this.props;
    this.getList([{id:label, value:value}])
  }

  componentDidUpdate(prevprops){
    console.log(7777,this.props.update)
    if(this.props.update){
      this.getList([]);
      this.props.updated()
    }
    if(this.props.ids && (this.state.updated === 0)) {
      this.onFilter('')
      this.setState({updated:1})
    }
    
  }

  

  onChange = (value,item) => {
    this.props.getValue(value)
  }

  

  render(){
    const {list} = this.state
    return(
      <div>
         <Select
         mode={this.props.isMulti?'multiple':''}
        showSearch
        allowClear={true}
        style={{ width: '100%'}}
        placeholder={this.props.placeholder}
        optionFilterProp="children"
        onChange={this.onChange}
        onSearch={this.onFilter}
        onClear={()=>this.props.getValue('')}
        value = {this.props.value}
        filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        >
            {list.map(item => (<Option value={item.value}>{item.label}</Option>))}


        </Select>
      </div>
      )
  }
}

export default SearchInput;
