import React, { useState, useEffect } from "react";
import { REACT_APP_BASE_URL } from "../../../../constants/ActionTypes";
import { message, Input, Tooltip, Button, Row, Col } from "antd";
import generateRandomPassword from "./generateRandomPassword";
import { SyncOutlined } from "@ant-design/icons";
import SaveButton from "../saveButton";
import { useDispatch } from "react-redux";
import { updateDataTable } from "../../../../appRedux/actions/Common";
import axios from "util/Api";

const BasicInfo = ({ data, headers }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    user_id: data.id,
    email: data.email,
    new_email: data.email,
    name: data.name,
    new_password: "",
  });
  const dispatch = useDispatch();
  console.log(888, values, 888, data);

  useEffect(() => {
    if (values.user_id !== data.id) {
      setValues({
        user_id: data.id,
        email: data.email,
        new_email: data.email,
        name: data.name,
        new_password: "",
      });
    }
  });

  const generatePass = () => {
    setValues({ ...values, new_password: generateRandomPassword() });
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onOk = () => {
    setLoading(true);
    axios
      .post(`${REACT_APP_BASE_URL}users/update/info`, values, { headers })
      .then(() => {
        message.success("Successfully updated");
        setLoading(false);
        dispatch(updateDataTable(true));
      })
      .then(() => {
        setValues({ ...values, email: values.new_email });
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.errors[0].msg, 5);
      });
  };

  return (
    <div>
      <label>
        <Row>
          <Col span={5}>
            <h4>Name</h4>
          </Col>
          <Col span={19}>
            <Input
              defaultValue={values.name}
              value={values.name}
              name="name"
              onChange={handleChange}
            />
            <br />
            <br />
          </Col>
        </Row>
      </label>
      <br />
      <label>
        <Row>
          <Col span={5}>
            <h4>Email</h4>
          </Col>
          <Col span={19}>
            <Input
              defaultValue={values.new_email}
              value={values.new_email}
              name="new_email"
              onChange={handleChange}
            />
            <br />
            <br />
          </Col>
        </Row>
      </label>
      <br />
      <label>
        <Row>
          <Col span={5}>
            <h4>New password</h4>
          </Col>
          <Col span={19}>
            <Input
              value={values.new_password}
              name="new_password"
              suffix={
                <Tooltip placement="topLeft" title="Generate random password">
                  <SyncOutlined onClick={generatePass} />
                </Tooltip>
              }
              onChange={handleChange}
            />
            <br />
            <br />
          </Col>
        </Row>
      </label>
      <div style={{ textAlign: "center" }}>
        <SaveButton loading={loading} action={onOk} name="Edit user" />
      </div>
    </div>
  );
};

export default BasicInfo;
