import React, {useState} from 'react';
import {Space,Button,Divider} from 'antd';
import AddPoints from './addPoints';
import CreateSubscription from './createSubscrioption';
import WithBase64 from './withBase64';
import ListSubscriptions from './listSubscriptions';
import { Tabs } from 'antd';
import { update } from 'lodash-es';

const { TabPane } = Tabs;

const Subscription = ({id,headers,data}) =>{
    const[content,setContent] = useState('list_subs')
    const [updateSubs,setUpdateSubs] = useState(false)
    const handleContent = (param)=>{
        setContent(param)
    }

    const updated = ()=>{
        setUpdateSubs(!updateSubs)
    }

    const renderContent = () =>{
       
    }
    return (
        <div>
            
            <Tabs defaultActiveKey="list_subs" onChange={handleContent} style={{padding:'10px'}}>
                <TabPane tab="All subscriptions" key="list_subs">
                <ListSubscriptions updateSubs={updateSubs} updated={updated} data={data} headers={headers}/>
                </TabPane>
                <TabPane  tab="Create Subscription" key="create_subscription">
                <CreateSubscription updated={updated} data={data} headers={headers}/>
                </TabPane>
                <TabPane tab="Base 64" key="base64">
                <WithBase64 updated={updated} data={data} headers={headers}/>
                </TabPane>
            </Tabs>

        </div>
    )


}

export default Subscription;