import React, { useState } from "react";
import ImageModal from "../../../components/books/uploadImgModal";
import { Upload, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import UploadImg from "../../../components/Images/uploadimg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
const UploadImage = ({
  endPoint,
  img_url,
  status,
  height,
  width,
  place,
  id,
  action,
  name,
}) => {
  const [modalData, setModalData] = useState({ src: null, visiblity: false });
  const [imgUrl, setImage] = useState(null);
  window["uploadRef" + id] = React.createRef();
  const { type } = useParams();

  const [endpoint, setEndpoint] = useState(endPoint);
  const headers = useSelector(({ auth }) => auth.header);

  const handleFile = (e) => {
    if (type === "contest_program" || type === "program") {
      handleProgramFile(e);
    } else {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setModalData({ src: fileReader.result, visiblity: true });
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleProgramFile = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    axios
      .post(`${REACT_APP_BASE_URL}uploads/book/img`, formData, {
        headers,
      })
      .then((res) => {
        axios
          .put(
            `${REACT_APP_BASE_URL}${endPoint}`,
            { image_url: res.data.data.file_url },
            { headers }
          )
          .then(() => {
            // window.location.reload(true)
            setImage(res.data.data.file_url);
            message.success("Successfuly updated");
          })
          .catch((err) =>
            err.response.data.errors.map((error) => message.error(error.msg, 5))
          );
      })
      .catch((err) => {
        message.error(err.response.data.errors[0].msg);
      });
  };

  const handelChange = (fileUrl) => {
    setImage(fileUrl);
    if (action) {
      action(name, fileUrl);
    }
    setModalData({ src: null, visiblity: false });
  };

  const props = {
    src: modalData.src,
    visiblity: modalData.visiblity,
    headers: headers,
    endPoint: endPoint,
    status: status,
  };
  return (
    <div>
      <input
        type="file"
        id="book_img"
        onChange={handleFile}
        ref={(e) => (window["uploadRef" + id].current = e)}
        style={{ display: "none" }}
      />
      <UploadImg
        getParentElem={() => window["uploadRef" + id].current.click()}
        imgUrl={imgUrl || img_url || ""}
        endPoint={endPoint}
        height={height}
        width={width}
      />

      {modalData.visiblity && (
        <ImageModal data={props} endPoint={endpoint} action={handelChange} />
      )}
    </div>
  );
};
export default UploadImage;
