import React, { Component } from "react";
import { Modal, Button, message } from "antd";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import "react-image-crop/dist/ReactCrop.css";

import axios from "util/Api";

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        unit: "%",
        x: 0,
        y: 0,
        width: 1000,
        aspect: 4 / 3.732,
      },
      croppedImageUrl: null,
      src: this.props.data.src,
      visible: this.props.data.visiblity,
      endPoint: this.props.endPoint,
      status: this.props.data.status,
    };
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const headers = this.props.data.headers;
    formData.append("file", this.state.croppedImage);

    const { status } = this.state;
    var fileUrl;
    axios
      .post(`${REACT_APP_BASE_URL}uploads/books/img`, formData, { headers })
      .then((res) => {
        fileUrl = res.data.data.file_url;
        if (status === "edit") {
          axios
            .put(
              `${REACT_APP_BASE_URL}${this.props.endPoint}`,
              { image_url: res.data.data.file_url },
              { headers }
            )
            .then((res) => {
              this.props.action(fileUrl);
              // window.location.reload(true)
              localStorage.setItem("book_img", fileUrl);

              message.success("Successfuly updated");
            })
            .catch((err) =>
              err.response.data.errors.map((error) =>
                message.error(error.msg, 5)
              )
            );
        }
      })
      .then(() => this.props.action(fileUrl))
      .catch((err) =>
        err.response.data.errors.map((error) => message.error(error.msg, 5))
      );
  };

  handleCancel = () => {
    this.props.action();
  };
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }
  render() {
    const { crop, src, visible } = this.state;
    return (
      <>
        <Modal
          title="Title"
          visible={visible}
          onOk={this.handleSubmit}
          // confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okText="Save"
        >
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              locked={true}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              style={{
                margin: "auto",
                maxHeight: "419px",
                alignSelf: "center",
                alignItems: "center",
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

export default ImageModal;
