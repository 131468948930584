import React, { Component } from "react";
import { Row, Col, Spin, Button, Divider, Space, message } from "antd";
import UploadChapters from "../../../components/buttons/UploadChapters";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import NewChapter from "../../../components/modal/AddNewChapter";
import DragNDrop from "../../../components/lists/DragAndDrop";
import PreveiwChapter from "../../../components/modal/preViewChapterModal";
import convertMsToHM from "../../../util/convertMsToHM";
import axios from "util/Api";

class Chapters extends Component {
  state = {
    loading: false,
    chapterStatus: null,
    chapter: false,
    list: [],
    showBtns: false,
    update: false,
    prev: false,
    counter_duration_ms: 172800000,
  };

  componentDidMount() {
    const { url, headers, id } = this.props;

    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState({ list: res.data.data, showBtns: true });
        }
      })
      .catch((err) => console.log(err.response));
  }
  downloadChapters = async () => {
    const { url, headers, id, data } = this.props;
    const title = data ? data.title : "";

    this.setState({ loading: true }, () => {
      axios
        .request({
          url: `${url}/${id}/download/chapters/audio`,
          method: "GET",
          headers,
          responseType: "blob", //important
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", title + ".zip"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err.response);
          err.response.data.errors.map((error) => message.error(error.msg, 5));
        })
        .then(() => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err.response);
          message.error("User does not have the right roles.");
        });
    });
  };

  addNewChapter = () => {
    const { url, headers, id } = this.props;
    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState({ list: res.data.data, showBtns: true, update: true });
        }
      })
      .then(() => {
        this.setState({ update: false });
      })
      .catch((err) => console.log(err.response));
  };

  onLoading = () => {
    this.setState({ loading: !this.state.loading }, () => this.addNewChapter());
  };

  onShowBtns = (state) => {
    this.setState({ showBtns: state });
  };
  action = () => {
    this.setState({ chapter: false }, () => this.addNewChapter());
  };
  onOpen = (prop) => {
    this.setState({ [prop]: !this.state[prop] });
  };

  render() {
    const { url, headers, id, data } = this.props;
    const { chapter, prev, list, showBtns } = this.state;
    return (
      <div style={{ margin: "10px" }}>
        <Spin spinning={this.state.loading}>
          {chapter && (
            <NewChapter
              book_id={id}
              headers={headers}
              url={url}
              data={data}
              visible={this.state.chapter}
              action={this.action}
              status={localStorage.getItem("chapterState")}
              chapterId={localStorage.getItem("chapterId")}
            />
          )}
          {prev && (
            <PreveiwChapter
              book_id={id}
              headers={headers}
              url={url}
              visible={this.state.prev}
              action={this.onOpen}
              chapterId={localStorage.getItem("chapterId")}
            />
          )}

          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                localStorage.setItem("chapterState", "create");
                this.onOpen("chapter");
              }}
            >
              Add new Chapter
            </Button>
            {showBtns && this.props.data.type != "quran" && (
              <UploadChapters
                url={url}
                headers={headers}
                id={id}
                action={this.onLoading}
              />
            )}
            {showBtns && this.props.data.type != "quran" && (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={this.downloadChapters}
              >
                Download All Chapters
              </Button>
            )}
          </Space>
          <Divider />
          <div style={{ padding: "15px" }}>
            <DragNDrop
              bookType={this.props.data.type}
              items={list}
              headers={headers}
              id={id}
              open={this.onOpen}
              url={url}
              action={this.addNewChapter}
              data={data}
              loading={this.onLoading}
              showBtns={this.onShowBtns}
              update={this.state.update}
              updated={this.props.updated}
            />
          </div>
          <br />
        </Spin>
      </div>
    );
  }
}

export default Chapters;
