import React, { useState } from "react";
import { Input, message, Row, Col, Button } from "antd";
import { REACT_APP_BASE_URL } from "../../../../constants/ActionTypes";
import axios from "util/Api";

const WithBase64 = ({ data, headers, updated }) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setToken(e.target.value);
  };

  const onOk = () => {
    setLoading(true);
    axios
      .post(
        `${REACT_APP_BASE_URL}subscribe/user/token/base64`,
        { token },
        { headers }
      )
      .then(() => {
        message.success("Successfully created");
        setLoading(false);
        updated();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.errors[0].msg);
      });
  };

  return (
    <>
      <h3>Add subscription with base64</h3>
      <Row justify="center">
        <Col span={24} style={{ textAlign: "center" }}>
          <label>
            <Input.TextArea
              placeholder="Token"
              rows={5}
              value={token}
              onChange={handleChange}
            />
          </label>
          <br />
          <br />
          <Button loading={loading} type="primary" onClick={onOk}>
            create subscription
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WithBase64;
