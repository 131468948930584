import {
  ADD_SUBSCRIBER_TAB,
  DELETE_SUBSCRIBER_TAB,
  UPDATE_SUBSCRIBER_TAB,
  REVIEW_SUBSCRIBER_TAB} from "../../constants/ActionTypes";

const INIT_STATE = {
  subscriberTabs:[
      
    ]

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case ADD_SUBSCRIBER_TAB: {
      return {...state, subscriberTabs: [action.payload,...state.subscriberTabs]};
    }

    case DELETE_SUBSCRIBER_TAB: {
        const tabs = state.subscriberTabs.filter(tab=>tab.id !==action.payload)
      return {
        ...state,
        subscriberTabs:tabs
      }
    }
    case UPDATE_SUBSCRIBER_TAB: {
        const tabs = state.subscriberTabs.map(tab=>{
          if(tab.id ===action.payload.oldData.id){
            return action.payload.newData
          }
          else{
            return tab
          }
        })

      return {
        ...state,
        subscriberTabs:tabs
      }
    }



    

    

    


    default:
      return state;
  }
}
