import React, {Component} from 'react';
import axios from 'axios';
import {REACT_APP_BASE_URL} from "../../constants/ActionTypes";
import { Modal, Input, Alert,Button,message,Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './modal.css';

const uploadRef = React.createRef();

class AuthorsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible:this.props.visible,
      status:this.props.status,
      loading:false,
      id:this.props.data.id,
      headers:this.props.headers,
      name:this.props.data.name || '',
      name_en:this.props.data.name_en || '',
      about :this.props.data.about || '',
      about_en: this.props.data.about_en || '',
      image_url:this.props.image_url || '',
      error :'',
      valid:false
    }
  }

  handleOk = () => {

    const {name, name_en, about,about_en, image_url, headers, id,status} = this.state;

      if (name === '' || about === '') {
        this.setState({valid:true})
      }
      else {
        this.setState({loading:true},()=>{
          if (status === 'create'){
            axios
            .post(`${REACT_APP_BASE_URL}authors`,{name, name_en, about, about_en, image_url},{headers})
            .then(() => {

              message.success('Successfully created')
              this.props.action();
              this.props.created();
            })
            .catch(err => message.error(err.response.data.errors[0].msg))
          }
          else if (status === 'edit') {
            axios
            .put(`${REACT_APP_BASE_URL}authors/${id}`,{name, name_en, about,about_en, image_url},{headers})
            .then(() => {
              this.props.updated();
              this.props.action();
            })
            .catch(err => message.error(err.response.data.errors[0].msg))

          }
        })
      }




  };

  handleCancel = () => {
    this.props.action()
  };

  handelChange= (e) => {
    e.preventDefault();
    const name = e.target.id;
    this.setState({[name]:e.target.value})
  }

  handleFile = e => {
    const formData = new FormData();
    const headers = this.state.headers
    formData.append('width', 500);
    formData.append('height', 500);
    formData.append('file', e.target.files[0]);
    axios
    .post(`${REACT_APP_BASE_URL}uploads/authors/img`, formData, { headers })
    .then (res => {
      this.setState({image_url:res.data.data.file_url,})
    })

  }


  render() {
    const {visible, name_en, name, about, about_en,image_url,valid,loading} = this.state;
    const { TextArea } = Input;
    return (
      <Modal
        title="Author Details"
        visible={visible }
        confirmLoading={loading}
        style={{ top: 10 }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
      <label>
      <h4>Arabic name <span className='req-astrick'>*</span></h4>
      <Input defaultValue={name} id='name' onChange={(e)=>this.handelChange(e)}/>
      {valid && !name && <p style={{color:'red'}}>This field is required!</p>}<br/>
      </label>
      <label>
      <h4>English name</h4>
      <Input defaultValue={name_en} id='name_en' onChange={this.handelChange}/><br/>
      </label>
      <label>
      <h4>About in arabic <span className='req-astrick'>*</span> </h4>
      <TextArea  rows={5} defaultValue={about} id='about' onChange={this.handelChange} />
      {valid && !about && <p style={{color:'red'}}>This field is required!</p>}<br/>
      </label>
      <label>
      <h4>About in english</h4>
      <TextArea  rows={5} defaultValue={about_en} id='about_en' onChange={this.handelChange} /><br/>
      </label>
      <input type='file' id='book_img' onChange={this.handleFile} ref={uploadRef} style={{display:'none'}}/><br/>
      <Row justify='center'>
      <img
          className='upImg'
          src={image_url || 'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'}
          alt='category image'
          style={{height:'180px',width:'150px',justifySelf:''}}
          onClick={() => uploadRef.current.click()} />
      </Row>

      </Modal>
    );
  }
}

export default AuthorsModal;

