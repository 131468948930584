import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {REACT_APP_BASE_URL} from "../../../constants/ActionTypes";
import {Prompt,useHistory,useParams} from 'react-router-dom';
import Info from './info'
import CuratedBooks from './Books';
import axios from 'axios';
import Swal from 'sweetalert2';


import { Tabs, Card } from 'antd';

const { TabPane } = Tabs;
const ModifyCurated = (props) => {
  const headers= useSelector(({auth}) => auth.header);
  const history = useHistory();
  const url = REACT_APP_BASE_URL+'curated';
  const [data,setData] = useState(props.location.aboutProps? props.location.aboutProps.data :'')
  const {id} = useParams();
  const [state,setState] = useState(props.location.aboutProps? props.location.aboutProps.status:localStorage.getItem('status').toString())
  const [disabled,setDisabled] = useState(false)
  const [count,setCount] = useState(0)
  const [prompt,setPrompt] = useState(true)

  useEffect(()=>{
    localStorage.setItem('desc', '')

    if(state === 'create' ) {
      localStorage.setItem('id', '');
      setDisabled(true)
      localStorage.setItem('status', 'create')
    }

    if(props.location.aboutProps&&props.location.aboutProps.data) {
      localStorage.setItem('status', props.location.aboutProps.status)
      localStorage.setItem('id', props.location.aboutProps.data.id);
    }

    else if(!props.location.aboutProps && count === 0 ) {
      const d = localStorage.getItem('id');
      setCount(1)


    }


  })

  const onRefresh =(pram) => {
    if (pram){
      setPrompt(false)
    }
    setState('edit');
    setDisabled(false);
  }

  return(
    <Card >
      <Prompt
      when={prompt}
      message= {(location, action)=> {
        Swal.fire({
        title: 'Are you sure you want to go ' + location.pathname+' ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'

      }).then((result) => {
       if (result.isConfirmed) {
         setPrompt(false)
        history.push(location)

        }

        })
        return false
      }}/>
      <Tabs defaultActiveKey="1" >
        <TabPane tab="Curated Info" key="1">
          <Info data={data || ''} id={id} status={state} url={url} headers={headers} onRefresh={onRefresh} />
        </TabPane>
        <TabPane tab="Books" disabled={disabled} key="2">
          <CuratedBooks url={REACT_APP_BASE_URL} headers={headers} id={id}/>
        </TabPane>

      </Tabs>
    </Card>
  )
}

export default ModifyCurated;
