import React, { Component, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import axios from "util/Api";

import { Col, Divider, Row, Radio } from "antd";
import AuthorsModal from "../modal/AuthorsModal";
import Cell from "./authorsSortableItem";

const SortableList = SortableContainer(
  ({ items, action, headers, updated }) => {
    return (
      <Row>
        <Col span={24}>
          {items &&
            items.map((value, i) => (
              <Cell
                updated={updated}
                headers={headers}
                key={`item-${value.id}`}
                index={i}
                i={i}
                value={value}
                action={action}
              />
            ))}
        </Col>
      </Row>
    );
  }
);

class SortableComponent extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    const { url, headers } = this.props;
    axios.get(`${url}/authors`, { headers }).then((res) => {
      const items = res.data.data;
      this.setState({ items });
    });
  }

  onAuthUpdated = () => {
    const { url, headers } = this.props;
    axios.get(`${url}/authors`, { headers }).then((res) => {
      const items = res.data.data;
      this.setState({ items }, () => this.props.updated());
    });
  };
  onRoleUpdate = (index, newRole) => {
    const items = this.state.items;
    items[index].role = newRole;
    this.setState({ items }, () => this.onSave());
  };

  onSave = () => {
    const { headers, url } = this.props;
    const body = this.state.items.map((item, i) => ({
      author_id: item.id,
      order: i + 1,
      role: item.role,
    }));

    axios
      .put(`${url}/authors/relation`, body, { headers })
      .catch((err) => console.log(err.response));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }),
      () => this.onSave()
    );
  };
  render() {
    return (
      <div className="gx-main-content gx-mb-4">
        <SortableList
          updated={this.onAuthUpdated}
          useDragHandle={true}
          headers={this.props.headers}
          items={this.state.items}
          onSortEnd={this.onSortEnd}
          action={this.onRoleUpdate}
        />
      </div>
    );
  }
}

export default SortableComponent;
