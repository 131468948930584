import React from "react";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "react-image-crop/dist/ReactCrop.css";
import "semantic-ui-css/semantic.min.css";

import setupInterceptors from "./util/setupInterceptors";
// Add this import:
import { AppContainer } from "react-hot-loader";

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
setupInterceptors();
