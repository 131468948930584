import React,{useState} from 'react';
import CreateSubOrGiftFrom   from "../createSubOrGiftForm";


const CreateGift = ({data,headers,updated}) =>{

    
    return(
        <>
        <CreateSubOrGiftFrom updated={updated} data={data} headers={headers} type={'gift'} endPoint={'gifts/add/client'}/>
        </>
    )

}

export default CreateGift;