import React, { useState } from "react";
import { Card, Input, Divider, Col, Row, Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { updateSubscriberTab } from "../../../appRedux/actions/Subscription";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import axios from "util/Api";

const { TextArea } = Input;

const SearchUser = ({ id, data, email, action }) => {
  const dispatch = useDispatch();
  const headers = useSelector(({ auth }) => auth.header);

  const [values, setValues] = useState({ email: "", base64: "" });

  const handleChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const emailSearch = () => {
    axios
      .post(
        `${REACT_APP_BASE_URL}wajeez_subscribers/datatable`,
        {
          pageSize: 10,
          page: 1,
          filtered: [{ id: "email", value: values.email }],
          sorted: [],
        },
        { headers }
      )
      .then((res) => {
        const data2 = res.data.data[0];
        dispatch(
          updateSubscriberTab(
            { id: id, email: email, data: data },
            { id: data2.id, email: data2.email, data: data2, visible: true }
          )
        );
        action(data2);
      })

      .catch((err) => message.error("this email not exist"));
  };

  return (
    <>
      <Input.Search
        onChange={handleChange}
        value={values.email}
        name="email"
        placeholder="Email"
        onSearch={emailSearch}
        enterButton
      />
    </>
  );
};

export default SearchUser;
