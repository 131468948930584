import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Radio,
  Select,
  message,
  Switch,
  Button,
  Space,
} from "antd";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import axios from "util/Api";
import { AiOutlinePlus, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

const { Option } = Select;

const ChapterQuestionModal = ({ id, book_id, action, visible, data }) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const headers = useSelector(({ auth }) => auth.header);
  const [state, setState] = React.useState({
    addAnswer: { show: false, value: "" },
    question: "",
    step: 1,
    question_id: null,
    answers: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${REACT_APP_BASE_URL}contest/chapters/${id}/contest`, {
        headers,
      })
      .then((res) => {
        const data = res.data.data;
        setState({
          ...state,
          addAnswer: { value: "", show: false },
          question: data.question,
          answers: data.answers,
          question_id: data.id,
        });
      })
      .catch((err) => {
        message.error(err.response.data.errors[0].msg);
      });
  };
  const addNewAnswer = () => {
    axios
      .post(
        `${REACT_APP_BASE_URL}contest/questions/${state.question_id}/answers`,
        {
          answer: state.addAnswer.value,
        },
        {
          headers,
        }
      )

      .then(() => {
        getData();
      })
      .catch((err) => {
        message.error(err.response.data.errors[0].msg);
      });
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onOk = () => {
    if (state.step === 1) {
      !state.question_id &&
        axios
          .post(
            `${REACT_APP_BASE_URL}contest/questions`,
            {
              book_id: book_id,
              chapter_id: id,
              question: state.question,
            },
            {
              headers,
            }
          )
          .then(() => {
            setState({ ...state, step: 2 });
          })
          .catch((err) => {
            message.error(err.response.data.errors[0].msg);
          });
      state.question_id && setState({ ...state, step: 2 });
    } else {
      action();
    }
  };
  useEffect(() => {
    getData();
  }, [state.step]);

  const handleCancel = () => {
    action();
  };

  const selectAnswer = (e) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}contest/questions/${state.question_id}/answers/${e.target.value}/correct`,
        {},
        {
          headers,
        }
      )
      .then(() => {
        setState({
          ...state,
          answers: state.answers.map((item) =>
            item.id === e.target.value
              ? { ...item, is_correct_answer: true }
              : { ...item, is_correct_answer: false }
          ),
        });
      })
      .catch((err) => {
        message.error(err.response.data.errors[0].msg);
      });
  };
  const closeAddAnswer = () => {
    setState({
      ...state,
      addAnswer: { value: "", show: false },
    });
  };
  return (
    <>
      <Modal
        title="Chapter Question Modal"
        visible={visible}
        width={700}
        okText={state.step === 2 ? "Save" : "next"}
        style={{ top: 10 }}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {state.step === 1 && (
          <label>
            <h4>
              Question <span className="req-astrick">*</span>
            </h4>
            <Input
              placeholder="Question"
              name="question"
              value={state.question}
              onChange={handleChange}
            />
          </label>
        )}
        {state.step === 2 && (
          <>
            <label>
              <Row justify="space-between">
                <Col span={12}>
                  <h4>
                    Answers <span className="req-astrick">*</span>{" "}
                    <span style={{ color: "gray", fontSize: "12px" }}>
                      Please Select The correct answer
                    </span>
                  </h4>
                </Col>

                <Button
                  onClick={() =>
                    setState({ ...state, addAnswer: { value: "", show: true } })
                  }
                  size={"small"}
                  icon={<AiOutlinePlus />}
                  type="primary"
                />
              </Row>

              {state.addAnswer.show && (
                <Row>
                  <Col span={18}>
                    <label>
                      <Input
                        placeholder="New Answer"
                        name="addAnswer"
                        value={state.addAnswer.value}
                        onChange={(e) => {
                          setState({
                            ...state,
                            addAnswer: {
                              ...state.addAnswer,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </Col>
                  <Space>
                    <Button
                      onClick={addNewAnswer}
                      size={"small"}
                      icon={<AiOutlineCheck />}
                      type="primary"
                    />
                    {/* <Button
                      onClick={closeAddAnswer}
                      size={"small"}
                      icon={<AiOutlineClose />}
                    /> */}
                  </Space>
                </Row>
              )}

              <Radio.Group
                onChange={selectAnswer}
                value={
                  state.answers.length
                    ? state.answers.find((item) => item.is_correct_answer)?.id
                    : null
                }
              >
                <Space direction="vertical">
                  {state.answers.map((item) => (
                    <Radio value={item.id}>{item.answer}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </label>
          </>
        )}
      </Modal>
    </>
  );
};

export default ChapterQuestionModal;
