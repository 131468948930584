import React, {useState} from 'react';
import {Space,Button,Divider} from 'antd';
import ApplyGift from './applyGift';
import CreateGift from './createGift';
import ListGifts from './listGifts'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Gifts = ({id,headers,data}) =>{
    const[content,setContent] = useState('list_gifts')
    const [updateGifts,setUpdateGifts] = useState(false)
    const handleContent = (param)=>{
        setContent(param)
    }

    const updated = () =>{
        setUpdateGifts(!updateGifts)
    }
    
    return (
        <div>
            
            <Tabs defaultActiveKey="list_subs" onChange={handleContent} style={{padding:'10px'}}>
                <TabPane tab="All gifts" key="list_gifts">
                <ListGifts updated={updated} updateGifts={updateGifts} data={data} headers={headers}/>
                </TabPane>
                <TabPane tab="Apply gift" key="apply_gift">
                <ApplyGift updated={updated} data={data} headers={headers}/>
                </TabPane>
                <TabPane tab="Create gift" key="create_gift">
                <CreateGift updated={updated} data={data} headers={headers}/>
                </TabPane>
            </Tabs>

        </div>
    )


} 

export default Gifts;