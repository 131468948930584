import React, { Component } from "react";
import { Modal, Button, Input, message, Row, Col } from "antd";
import axios from "axios";
import Player from "../inputs/AudioPlayer";
import VideoPlayer from "../inputs/VideoPlayer";
class PreveiwChapter extends Component {
  state = {
    title: "",
    desc: "",
    loading: false,
    visible: this.props.visible || false,
    validation: false,
    audio_url: "",
    content_type: "",
  };

  componentDidMount() {
    const { url, id, book_id, headers, chapterId } = this.props;

    axios
      .get(`${url}/${book_id}/chapters/${chapterId}`, { headers })
      .then((res) => {
        const { title, desc, audio_url, content_type } = res.data.data;
        this.setState({ title, desc, audio_url, content_type });
        localStorage.setItem("desc", res.data.data.desc);
      })
      .catch((err) => console.log(err.response));
  }

  handleCancel = () => {
    this.props.action("prev");
  };

  render() {
    const { headers, visible } = this.props;
    const { validation, loading, title, desc, audio_url, content_type } =
      this.state;
    return (
      <Modal
        title="Preveiw Chapter"
        visible={visible}
        style={{ top: "16px", padding: "0 15px" }}
        width={800}
        confirmLoading={loading}
        onCancel={this.handleCancel}
        cancelText="Cancel"
        footer={null}
      >
        <h3 className="chapter-title">{title}</h3>
        <br />
        <br />
        <div className="desc-para" dangerouslySetInnerHTML={{ __html: desc }} />
        <br />
        <br />
        {audio_url &&
          (content_type === "video" ? (
            <VideoPlayer src={audio_url} />
          ) : (
            <Player src={audio_url} />
          ))}
      </Modal>
    );
  }
}

export default PreveiwChapter;
