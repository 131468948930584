import React, { Component } from "react";
import { Row, Col, Button, Space, message } from "antd";
import UploadImage from "./UploadImge";
import DiscardButton from "../../../components/buttons/discard";
import axios from "util/Api";

import { Redirect } from "react-router-dom";
import SearchInput from "../../../components/inputs/SearchInput";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
class RelatedBooks extends Component {
  state = {
    loading: false,
    books: [],
    book: null,
  };

  componentDidMount() {
    const { url, headers, id } = this.props;
    axios
      .get(`${url}books/${id}/related`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState({ books: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  getNewBook = (id) => {
    const { url, headers } = this.props;
    axios.get(`${url}books/${id}`, { headers }).then((res) => {
      this.setState({ book: res.data.data });
    });
  };

  addNewBook = () => {
    if (this.state.book) {
      let exist = false;
      this.state.books.forEach((item) => {
        if (item.id === this.state.book.id) {
          exist = true;
        }
      });
      if (!this.state.book.available) {
        console.log(this.state.book.available);
        message.error("this book not available");
      } else if (!exist) {
        let books = this.state.books;
        books.push(this.state.book);
        this.setState({ books });
      } else {
        message.error("this book already exist");
      }
    } else {
      message.error("please choose a book");
    }
  };

  deleteBook = (key) => {
    console.log(777, key);
    let books = this.state.books;
    books.splice(key, 1);
    this.setState({ books });
  };

  onSave = () => {
    const { url, headers, id } = this.props;
    const booksIds = this.state.books.map((item) => item.id);
    if (booksIds.length > 0) {
      this.setState({ loading: true });

      axios
        .post(
          `${url}books/${id}/related`,
          { books_lists: booksIds },
          { headers }
        )
        .then(() => {
          this.setState({ loading: false });
          message.success("Successfuly add");
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(err.response.data.message);
        });
    } else {
      message.error("Please add books");
    }
  };

  render() {
    const { loading, books } = this.state;
    const { url, headers } = this.props;
    console.log(111, this.props);
    return (
      <div style={{ margin: "10px " }}>
        <div style={{ display: "inline-flex" }}>
          <SearchInput
            url={url}
            headers={headers}
            addNewBook={this.getNewBook}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginLeft: "5px" }}
            onClick={this.addNewBook}
          >
            Add book
          </Button>
        </div>
        <div style={{ width: "50%", margin: "20px 0 40px 0" }}>
          {books.map((item, i) => (
            <div
              key={item.id}
              style={{
                border: "1px solid #D9D9D9",
                padding: "10px",
                margin: "10px 0",
              }}
            >
              <Row align="middle">
                <Col sm={4} xs={8} style={{ height: "50px" }}>
                  <img src={item.image_url} style={{ width: "50px" }} />
                </Col>
                <Col sm={17} xs={16}>
                  <h3>{item.title_ar}</h3>
                </Col>
                <Col sm={3}>
                  <Button
                    danger
                    id={i}
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => this.deleteBook(i)}
                    style={{ verticalAlign: "middle", margin: "auto" }}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </div>
        <Row justify="center">
          <Space>
            <DiscardButton endpoint="/booksManager" />
            <Button onClick={this.onSave} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        </Row>
      </div>
    );
  }
}

export default RelatedBooks;
