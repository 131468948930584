import React from "react";
import { IoMdBookmarks, IoIosBusiness } from "react-icons/io";
import { AiOutlineHistory, AiFillStar, AiFillTags } from "react-icons/ai";
import {
  FaLayerGroup,
  FaFeatherAlt,
  FaSwatchbook,
  FaHeart,
  FaUsers,
  FaQuestion,
} from "react-icons/fa";
import { BsBookHalf, BsBagFill, BsFillMicFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { CgMediaPodcast } from "react-icons/cg";
import { ImFileText } from "react-icons/im";
import { RiServiceFill, RiAdminFill } from "react-icons/ri";
import { BiBookAdd } from "react-icons/bi";
import { GoVersions } from "react-icons/go";
import { MdFeaturedPlayList, MdKeyboardVoice } from "react-icons/md";
import { GrAction, GrStatusCriticalSmall, GrObjectGroup } from "react-icons/gr";
import { TiMessages } from "react-icons/ti";
import allahImg from "../../assets/images/allah.png";
import quran from "../../assets/images/quran.png";
import contest from "../../assets/images/contest.png";
import program from "../../assets/images/program.png";
import star from "../../assets/images/star.png";
import pray from "../../assets/images/pray.png";
import short from "../../assets/images/short.png";
import cinema from "../../assets/images/cinema.png";
import novel from "../../assets/images/novel.png";

const items = [
  {
    title: "Standard Books",
    link: "booksManager/standard",
    key: "datatable.books",
    icon: <IoMdBookmarks className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Short Books",
    link: "booksManager/short",
    key: "datatable.books",
    icon: <img src={short} className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Novels",
    link: "booksManager/novel",
    key: "datatable.books",
    icon: <img src={novel} className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Theater play",
    link: "booksManager/theater_play",
    key: "datatable.books",
    icon: <img src={cinema} className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Holy Quran",
    link: "booksManager/ramadan/quran",
    key: "datatable.books",
    icon: <img src={quran} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },
  {
    title: "Program",
    link: "booksManager/ramadan/program",
    key: "datatable.books",
    icon: <img src={program} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },
  {
    title: "Program with contest",
    link: "booksManager/ramadan/contest_program",
    key: "datatable.books",
    icon: <img src={contest} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },

  {
    title: "Live the ayah",
    link: "quickReads/ramadan/live_the_ayah",
    key: "datatable.quick_reads",
    icon: <img src={star} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },
  {
    title: "Allah names",
    link: "quickReads/ramadan/allah_name",
    key: "datatable.quick_reads",
    icon: <img src={allahImg} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },
  {
    title: "Prayer",
    link: "quickReads/ramadan/prayer",
    key: "datatable.quick_reads",
    icon: <img src={pray} className="icon icon-dasbhoard" />,
    subMenu: "ramadan",
  },
  {
    title: "Quick Reads",
    link: "quickReads",
    key: "datatable.quick_reads",
    icon: <AiOutlineHistory className="icon icon-dasbhoard" />,
  },
  {
    title: "Voice Overs",
    link: "voice-overs",
    key: "datatable.voice_overs",
    icon: <BsFillMicFill className="icon icon-dasbhoard" />,
  },
  // {
  //   title:'Categories',
  //   link:'categories/general',
  //   key:'datatable.categories',
  //   icon:<FaLayerGroup className='icon icon-dasbhoard'/>,
  //   subMenu:'books'
  // },
  {
    title: "Faq Categories",
    link: "categories/faq",
    key: "datatable.categories",
    icon: <FaLayerGroup className="icon icon-dasbhoard" />,
    subMenu: "customers",
  },
  {
    title: "Authors",
    link: "authors",
    key: "datatable.authors",
    icon: <FaFeatherAlt className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Free Book",
    link: "freeBook",
    key: "datatable.schedule_books",
    icon: <BsBookHalf className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Curated",
    link: "curated",
    key: "datatable.curated",
    icon: <FaSwatchbook className="icon icon-dasbhoard" />,
  },
  // {
  //   title:'Interests',
  //   link:'interests',
  //   key:'datatable.interests',
  //   icon:<FaHeart className='icon icon-dasbhoard'/>
  // },

  {
    title: "Tags",
    link: "tags",
    key: "datatable.tags",
    icon: <AiFillTags className="icon icon-dasbhoard" />,
  },
  {
    title: "Wajeez Admins",
    link: "users",
    key: "datatable.users",
    icon: <RiAdminFill className="icon icon-dasbhoard" />,
    subMenu: "users",
  },
  {
    title: "Wajeez Users",
    link: "subscribers",
    key: "datatable.wajeez_users",
    icon: <HiUsers className="icon icon-dasbhoard" />,
    subMenu: "users",
  },

  {
    title: " Reviews",
    link: "reviews",
    key: "datatable.book_ratings",
    icon: <AiFillStar className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Actions",
    link: "inapp/actions",
    key: "datatable.inapp_message_actions",
    icon: <GrAction className="icon icon-dasbhoard" />,
    subMenu: "inapp",
  },
  {
    title: "Messages",
    link: "inapp/messages",
    key: "datatable.inapp_messages",
    icon: <TiMessages className="icon icon-dasbhoard" />,
    subMenu: "inapp",
  },

  {
    title: "Criteria",
    link: "inapp/criteria",
    key: "datatable.inapp_group_criteria",
    icon: <GrStatusCriticalSmall className="icon icon-dasbhoard" />,
    subMenu: "inapp",
  },
  {
    title: "Group",
    link: "inapp/group",
    key: "datatable.inapp_groups",
    icon: <GrObjectGroup className="icon icon-dasbhoard" />,
    subMenu: "inapp",
  },

  {
    title: "Shortcast",
    link: "shortcast",
    key: "datatable.podcast_hubs",
    icon: <CgMediaPodcast className="icon icon-dasbhoard" />,
  },

  {
    title: "Careers",
    link: "departments",
    key: "datatable.departments",
    icon: <BsBagFill className="icon icon-dasbhoard" />,
  },
  // {
  //   title:'Versions',
  //   link:'versions',
  //   key:'datatable.versions',
  //   icon:<GoVersions className='icon icon-dasbhoard'/>
  // },
  {
    title: "Customer Care",
    link: "customer-care",
    key: "customer_care",
    icon: <RiServiceFill className="icon icon-dasbhoard" />,
    subMenu: "customers",
  },
  {
    title: "FAQs",
    link: "faqs",
    key: "datatable.faqs",
    icon: <FaQuestion className="icon icon-dasbhoard" />,
    subMenu: "customers",
  },
  {
    title: "Requested Books",
    link: "requestedBooks",
    key: "datatable.requested_books",
    icon: <BiBookAdd className="icon icon-dasbhoard" />,
    subMenu: "books",
  },
  {
    title: "Business Groups",
    link: "businesses",
    key: "datatable.business_groups",
    icon: <IoIosBusiness className="icon icon-dasbhoard" />,
  },
  {
    title: "Pages",
    link: "pages",
    key: "datatable.web_pages",
    icon: <ImFileText className="icon icon-dasbhoard" />,
  },
];

export default items;
