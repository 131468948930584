import React, { Component } from "react";
import axios from "util/Api";

import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";
import Select from "react-select";
import "./style.css";

class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      data: [],
      headers: this.props.headers,
      id: this.props.id,
      endPoint: this.props.endpoint,
      text: this.props.text,
      selected: [],
      isLoading: false,
    };
  }
  componentDidUpdate() {
    if (this.props.update) {
      const { headers, endPoint, url, id } = this.state;
      axios
        .get(`${url}/${id}/${endPoint}`, { headers })
        .then((res) => {
          if (res.data) {
            const selected = res.data.data.map((auth) => ({
              value: auth.id,
              key: auth.id,
              label: auth.name,
            }));
            this.setState({ selected });
          }
        })
        .then(() => this.props.updated())
        .catch((err) => console.log(err.response));
    }
  }
  componentDidMount() {
    const { headers, endPoint, url, id } = this.state;
    this.setState({ isLoading: true });
    axios
      .post(
        `${REACT_APP_BASE_URL}${endPoint}/datatable`,
        {
          pageSize: 1000,
          page: 1,
          filtered: this.props.filtered
            ? [{ id: this.props.filtered.id, value: this.props.filtered.value }]
            : [],
        },
        { headers }
      )
      .then((res) => {
        const data = res.data.data.map((auth) => ({
          value: auth.id,
          key: auth.id,
          label: auth.name,
        }));
        this.setState({ data });
      })
      .then(() => {
        if (this.props.interests) {
          if (id) {
            axios
              .get(`${REACT_APP_BASE_URL}interests/${id}/categories`, {
                headers,
              })
              .then((res) => {
                if (res.data) {
                  const selected = res.data.data.map((auth) => ({
                    value: auth.id,
                    key: auth.id,
                    label: auth.name,
                  }));
                  const cat_ids = res.data.data.map((auth) => auth.id);
                  this.props.cat(cat_ids);
                  this.setState({ selected });
                }
              });
          }
        } else {
          axios.get(`${url}/${id}/${endPoint}`, { headers }).then((res) => {
            if (res.data) {
              const selected = res.data.data.map((auth) => ({
                value: auth.id,
                key: auth.id,
                label: auth.name,
              }));
              this.setState({ selected });
            }
          });
        }
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  }
  onFilter = (value) => {
    const { headers, endPoint } = this.state;
    axios
      .post(
        `${REACT_APP_BASE_URL}${endPoint}/datatable`,
        {
          pageSize: 1000,
          page: 1,
          filtered: this.props.filtered
            ? [
                {
                  id: this.props.filtered.id,
                  value: this.props.filtered.value,
                },
                { id: "name", value: value },
              ]
            : [{ id: "name", value: value }],
        },
        { headers }
      )
      .then((res) => {
        const data = res.data.data.map((auth) => ({
          key: auth.id,
          label: auth.name,
          value: auth.id,
        }));
        this.setState({ data });
      })
      .catch((err) => this.setState({ data: [] }));
  };
  onDeselect = (val) => {
    const { url, endpoint, id, headers } = this.props;
    if (endpoint === "authors") {
      axios
        .delete(`${url}/${id}/authors/${val}`, { headers })
        .catch((err) => console.log(err.response));
    }
  };

  handleChange = (value, action) => {
    if (action.action === "remove-value") {
      this.onDeselect(action.removedValue.value);
    }

    const { url, endpoint, id, headers, getData } = this.props;
    let cat_ids;
    if (value) {
      cat_ids = value.map((cat) => cat.value);
    } else {
      cat_ids = [];
    }
    const body =
      endpoint === "authors" && value
        ? {
            author_id: value[value.length - 1].value,
            role: value.length === 1 ? "primary" : "secondary",
          }
        : this.props.cat
        ? { ids: cat_ids }
        : { cat_ids: cat_ids };
    this.setState({ selected: value });
    if (this.props.interests || this.props.withAction) {
      this.props.cat(cat_ids);
    } else {
      axios
        .post(`${url}/${id}/${endpoint}`, body, { headers })
        .catch((err) => console.log(err.response));
      if (endpoint === "authors") {
        this.props.action(value ? value.length : 0);
      }
    }
  };

  render() {
    const { data, selected, isLoading } = this.state;
    return (
      <div>
        <Select
          className="books-selector"
          isClearable={false}
          options={data}
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          style={{ width: "100%", zindex: "1 !important" }}
          getValue={this.onFilter}
          placeholder="Please select"
          onChange={this.handleChange}
          onInputChange={this.onFilter}
          value={selected}
          backspaceRemovesValue={false}
        />
      </div>
    );
  }
}

export default Selector;
