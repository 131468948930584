import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import {useSelector} from 'react-redux'

interface Values {
  name: string;
  old_password: string;
  new_password: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const ProfileForm: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const user = useSelector(({auth}) => auth.authUser);
  const [changePass,setChangePass] = useState(false)
  return (
    <Modal
      visible={visible}
      title="Create a new collection"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{name:user ,new_password: '',old_password:'' }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
        label="Password"
        name="old_password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      {changePass ?<Form.Item
        label="New password"
        name="new_password"
        
      >
        <Input.Password />
      </Form.Item> : <p style={{color:'#5D5D5D'}} >Do you want to change your password? <a  onClick={()=>setChangePass(true)}>Yes</a></p>}
      </Form>
    </Modal>
  );
};

export default ProfileForm;