import React, {Component,useState} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import axios from 'axios';
import {Col, Divider,Row, Radio} from 'antd';
import AuthorsModal from '../modal/AuthorsModal';

const EndHandle = SortableHandle(() =>
<span className="gx-draggable-icon gx-pt-2" style={{cursor:'pointer'}}>
  <i className="icon icon-expand" style={{fontSize: 25}}/>
</span>);

const Cell = (({value,index,action,i,headers,updated}) =>{
  const [role,setRole] = useState(value.role);
  const [visible,setVisible] = useState(false);
  const handleChange = (e) => {
    setRole(e.target.value);
    action(i,e.target.value);
  }

  const visiAction = () => {

    setVisible(!visible)
    updated()

  }
  return(
  <Row>
    {visible && <AuthorsModal action={visiAction} visible={visible} headers={headers} status={'edit'} data={value}/>}
    <Divider/>
    <EndHandle/>
    <div onClick={visiAction} style={{alignSelf:'center'}} className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2"><a >{value.name}</a></div>

    <Radio.Group  onChange={handleChange} defaultValue={value.role} value={role} optionType="button"
        buttonStyle="solid">
    <Radio.Button value="primary">Primary</Radio.Button>
    <Radio.Button value="secondary">Secondary</Radio.Button>
    </Radio.Group>



  </Row>
  )
});

export default SortableElement(Cell);
