import React, { useState } from "react";
import { REACT_APP_BASE_URL } from "../../../../constants/ActionTypes";
import axios from "util/Api";

import { message, Button, Row, Col, Input } from "antd";
import { useDispatch } from "react-redux";
import { updateDataTable } from "../../../../appRedux/actions/Common";
const ApplyGift = ({ data, headers }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [validation, setValidation] = useState(false);
  const dispatch = useDispatch();
  const Apply = () => {
    if (!token) {
      setValidation(true);
    } else {
      setValidation(false);
      setLoading(true);
      axios
        .post(
          `${REACT_APP_BASE_URL}gifts/${token}/apply`,
          { user_id: data.id },
          { headers }
        )
        .then(() => {
          setLoading(false);
          message.success("Successfuly applied");
        })
        .then(() => dispatch(updateDataTable(true)))
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data.errors[0].msg, 5);
        });
    }
  };

  const handleChange = (e) => {
    setToken(e.target.value);
  };

  return (
    <>
      <h3>Apply Gift</h3>
      <Row>
        <Col span={18}>
          <label>
            <Input
              placeholder="Token"
              name="token"
              value={token}
              onChange={handleChange}
            />
            {validation && !token && (
              <h4 className="error">you need to enter token!</h4>
            )}
          </label>
        </Col>
        <Col span={6}>
          <Button loading={loading} type="primary" onClick={Apply}>
            Apply
          </Button>{" "}
        </Col>
      </Row>
    </>
  );
};

export default ApplyGift;
