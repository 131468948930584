import React, { useState, useCallback, useRef, useEffect } from "react";
import { Table, Row, Button, message } from "antd";
import update from "immutability-helper";
import { BookOutlined } from "@ant-design/icons";
import SearchInput from "../../../components/inputs/SearchInput";
import axios from "util/Api";

import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const RNDContext = createDndContext(HTML5Backend);

const type = "DragableBodyRow";

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const CuratedBooks = ({ url, headers, id }) => {
  const [data, setData] = useState(null);
  const [newBook, setNewBook] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (imag) => {
        return imag ? (
          <img
            src={imag}
            alt={"Book Image"}
            style={{ height: "50px", width: "50px" }}
          />
        ) : (
          <BookOutlined style={{ fontSize: "50px", color: "#1E7A84" }} />
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      key: "action",
      render: (x, i) => <a onClick={() => deletBook(i.id)}>Delete</a>,
    },
  ];

  const deletBook = useCallback(
    (ID) => {
      let index;
      let ind;

      data.forEach((item, i) => {
        if (item.id === ID) {
          index = i;
        }
      });
      ids.forEach((item, i) => {
        if (item === ID) {
          ind = i;
        }
      });

      if (ids.length > 1) {
        ids.splice(ind, 1);
        data.splice(index, 1);

        setData([...data]);
        setIds([...ids]);
      } else {
        message.error("You cant delete while there is only one book", 10);
      }
    },
    [data, newBook, ids]
  );

  useEffect(() => {
    if (!data) {
      axios
        .get(`${url}curated/${id}/books?page=1&size=10000`, { headers })
        .then((res) => {
          const d = res.data.data.list.map((item, i) => ({
            key: i,
            id: item.id,
            title: item.title_ar,
            image_url: item.image_url,
          }));
          const ids = d.map((item) => item.id);
          setIds(ids);
          setData(d);
        });
    } else {
      let arr = data.map((item) => item.id);
      axios.post(`${url}curated/${id}/books`, { book_ids: arr }, { headers });
    }
  }, [data, newBook, ids]);

  const addNewBook = useCallback(
    (id) => {
      axios.get(`${url}books/${id}`, { headers }).then((res) => {
        const d = {
          key: data.length,
          id: res.data.data.id,
          title: res.data.data.title,
          image_url: res.data.data.image_url,
        };
        setNewBook(d);
      });
    },
    [newBook, data]
  );

  const submitBook = useCallback(() => {
    if (newBook) {
      setLoading(!loading);
      data.unshift(newBook);
      ids.unshift(newBook.id);
      setIds([...ids]);
      setData([...data]);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      message.error("you need to choose a book");
    }
  }, [data, newBook, ids]);
  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const manager = useRef(RNDContext);
  return (
    <div>
      <div style={{ margin: "5px 0px", display: "inline-flex" }}>
        <SearchInput
          url={url}
          headers={headers}
          addNewBook={addNewBook}
          ids={ids}
        />
        <Button
          type="primary"
          onClick={submitBook}
          style={{ marginLeft: "5px" }}
        >
          Add This Book
        </Button>
      </div>
      <DndProvider manager={manager.current.dragDropManager}>
        <Table
          loading={loading}
          scroll={{ y: 240 }}
          footer={() => data && "Number of books " + data.length}
          pagination={false}
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </div>
  );
};

export default CuratedBooks;
