import React, {Component} from 'react';
import  { Editor, } from '@tinymce/tinymce-react';
import TinyMCE from 'react-tinymce';


class CK extends Component {

  state = {
    value: null,
  }

  onChange = (e) => {
    if(this.props.action){
      this.props.action(this.props.name, e)
    }
    else{
      localStorage.setItem(this.props.name, e);

    }
  }

  render () {
    const {value} = this.state
    return (
      <div>
        <Editor
        style={{textAlign:'right'}}
         initialValue={this.props.data|| value}
         value={this.props.data}
         disabled={this.props.disabled||false}
         init={{
          
          directionality: 'rtl',
           height: this.props.height || 250,
           menubar: false,
           invalid_elements: 'table,td,tr,th',
           plugins: [
            'lists paste fullscreen searchreplace',
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'emoticons',
             'insertdatetime media table paste code help wordcount',
             
             
             'directionality'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat \ ltr rtl | \media link image emoticons |\help ',
             automatic_uploads: true,
             file_picker_callback: function (callback, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
                  input.click();
                  input.onchange = function () {
                      var file = input.files[0];
                      var reader = new FileReader();
                      reader.onload = function (e) {
                          console.log('name',e.target.result);
                          callback(e.target.result, {
                              alt: file.name
                          });
                      };
                      reader.readAsDataURL(file);
                  };
              
          },

            
         }}
         onEditorChange={this.onChange}
      />
      </div>

    );
  }
}

export default CK;
