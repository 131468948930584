import React, { Component } from "react";
import Player from "../inputs/AudioPlayer.js";
import { Button, Spin, Row, Col, Space, Popconfirm, message } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes.js";
import "./audioStyle.css";

const InputRef = React.createRef();

class UploadAudio extends Component {
  state = {
    loading: false,
    selectedFile: null,
    msg: "",
    color: "",
    src: this.props.audio_url,
    del: true,
  };

  onFileChange = (event) => {
    this.setState(
      { selectedFile: event.target.files[0], loading: true },
      () => {
        this.onFileUpload();
      }
    );
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    const { endpoint, headers, id, setAudio } = this.props;
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    axios
      .post(`${REACT_APP_BASE_URL}uploads/${endpoint}/audio`, formData, {
        headers,
      })
      .then((res) => {
        this.setState({ loading: false });
        // setAudio && setAudio(res.data.data.file_url);
        localStorage.setItem("audio_url", res.data.data.file_url);

        this.setState({ src: res.data.data.file_url });
        this.setState({ del: true });
      })
      .catch((err) => {
        this.setState({ loading: false });
        err.response.data.errors.map((error) => message.error(error.msg, 5));
      });
  };

  render() {
    const { src, loading, del } = this.state;
    return (
      <div>
        <input
          type="file"
          onChange={this.onFileChange}
          ref={InputRef}
          style={{ display: "none" }}
        />
        <Row>
          <Col span={6}>
            {loading ? (
              <Row justify="center">
                <Spin size="large" />
              </Row>
            ) : (
              <Button
                icon={<UploadOutlined />}
                onClick={() => InputRef.current.click()}
              >
                Upload Audio
              </Button>
            )}
          </Col>
          {del && (
            <>
              <Col span={2}>
                {src || this.props.audio_url ? (
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to delete audio?"
                    onConfirm={() => {
                      localStorage.setItem("audio_url", "");
                      this.setState({ del: false });
                      this.setState({ src: "" });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      type="text"
                      icon={<CloseOutlined />}
                      size="small"
                    />
                  </Popconfirm>
                ) : (
                  <div></div>
                )}
              </Col>
              <Col span={16}>
                {src || this.props.audio_url ? (
                  <Player src={src || this.props.audio_url} />
                ) : (
                  <div></div>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
}

export default UploadAudio;
