import React, { Component } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Row, Col, Collapse, Spin, Button, Popconfirm, Space } from "antd";
import NewChapter from "../../../components/modal/AddNewChapter";
import Player from "../../../components/inputs/AudioPlayer";
import axios from "util/Api";
import VideoPlayer from "../../../components/inputs/VideoPlayer";
import ChapterQuestionModal from "../../../components/modal/chapterQuestionModal";
import { REACT_APP_BASE_URL } from "../../../constants/ActionTypes";
import { element } from "prop-types";
class ViewChapters extends Component {
  state = {
    loading: false,
    chapter: false,
    list: [],
    showBtns: false,
    update: false,
    prev: false,
    arr: [],
    reciters: [],
    recitersLoader: false,
    qustionModalState: {
      visible: false,
      id: null,
    },
  };

  componentDidMount() {
    const { url, headers, id, data } = this.props;
    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              list: res.data.data,
              arr: [...res.data.data.keys()],
              showBtns: true,
            },
            () => {
              const files = [];
              this.setState({ recitersLoader: false });
              data.type === "quran" &&
                res.data.data.map((item, i) =>
                  axios
                    .get(`${REACT_APP_BASE_URL}chapters/${item.id}/reciters`, {
                      headers,
                    })
                    .then((res) =>
                      this.setState({
                        reciters: [
                          ...this.state.reciters,
                          { data: res.data.data, id: item.id },
                        ],
                      })
                    )
                    .then(() => {
                      i === res.data.data.length - 1 &&
                        this.setState({ recitersLoader: false });
                    })
                );
            }
          );
        }
      })
      .catch((err) => console.log(err.response));
  }

  componentDidUpdate() {
    const { url, headers, id, updated } = this.props;

    if (this.props.updateChapters) {
      axios
        .get(`${url}/${id}/chapters`, { headers })
        .then((res) => {
          if (res.data) {
            this.setState({
              list: res.data.data,
              arr: [...res.data.data.keys()],
              showBtns: true,
            });
          }
        })
        .catch((err) => console.log(err.response));
      updated(false);
    }
  }

  addNewChapter = () => {
    const { url, headers, id } = this.props;
    axios
      .get(`${url}/${id}/chapters`, { headers })
      .then((res) => {
        if (res.data) {
          this.setState({
            list: res.data.data,
            arr: [...res.data.data.keys()],
            showBtns: true,
            update: true,
          });
        }
      })
      .then(() => {
        this.setState({ update: false });
      })
      .catch((err) => console.log(err.response));
  };

  onLoading = () => {
    this.setState({ loading: !this.state.loading }, () => this.addNewChapter());
  };

  action = () => {
    this.setState({ chapter: false }, () => this.addNewChapter());
  };
  onOpen = (prop) => {
    this.setState({ [prop]: !this.state[prop] });
  };

  onDelete = (chapter_id) => {
    const { url, id, headers } = this.props;
    axios
      .delete(`${url}/${id}/chapters/${chapter_id}`, { headers })
      .then(() => this.action())
      .catch((err) => console.log(err.response));
  };

  genExtra = (id, type) => (
    <Space>
      {type === "contest_program" && (
        <QuestionOutlined
          className="icon-btns"
          onClick={() =>
            this.setState({ qustionModalState: { visible: true, id: id } })
          }
        />
      )}
      <EditOutlined
        className="icon-btns"
        style={{ marginLeft: "10px" }}
        onClick={() => {
          localStorage.setItem("chapterId", id);
          this.setState({ chapter: true });
        }}
      />
      <Popconfirm
        title="Are you sure you want to delete this ?"
        onConfirm={() => this.onDelete(id)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined className="icon-btns" />
      </Popconfirm>
    </Space>
  );

  render() {
    const { url, headers, id, data } = this.props;
    const { chapter, list, arr, qustionModalState, reciters, recitersLoader } =
      this.state;
    const { Panel } = Collapse;
    console.log(reciters);
    return (
      <div style={{ margin: "10px", direction: "rtl" }}>
        <Spin spinning={this.state.loading}>
          {chapter && (
            <NewChapter
              book_id={id}
              headers={headers}
              url={url}
              data={data}
              visible={this.state.chapter}
              action={this.action}
              status={"edit"}
              chapterId={localStorage.getItem("chapterId")}
            />
          )}

          <Collapse
            defaultActiveKey={arr}
            activeKey={arr}
            onChange={(e) => {
              this.setState({ arr: e });
            }}
          >
            {list.map((item, i) => {
              return (
                <Panel
                  style={{ fontSize: "18px", marginBottom: "10px" }}
                  className="view-chapter-collapse"
                  header={item.title}
                  key={i}
                  extra={this.genExtra(item.id, item.type)}
                >
                  <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                  <div style={{ direction: "ltr", marginTop: "20px" }}>
                    {item.audio_url &&
                      (item.content_type === "video" ? (
                        <VideoPlayer src={item.audio_url} />
                      ) : (
                        <Player src={item.audio_url} />
                      ))}
                    {data.type === "quran" &&
                    reciters.length &&
                    reciters.find((ele) => ele.id === item.id) ? (
                      reciters
                        .find((ele) => ele.id === item.id)
                        .data.map((item) => (
                          <Row>
                            <Col span={4}>{item.reciter_name}</Col>
                            <Col span={20}>
                              {" "}
                              <Player src={item.audio_url} />
                            </Col>
                          </Row>
                        ))
                    ) : recitersLoader ? (
                      <Spin />
                    ) : null}
                  </div>
                </Panel>
              );
            })}
          </Collapse>
          <br />
        </Spin>
        {qustionModalState.visible && (
          <ChapterQuestionModal
            id={qustionModalState.id}
            book_id={id}
            action={() =>
              this.setState({ qustionModalState: { visible: false, id: null } })
            }
            visible={qustionModalState.visible}
          />
        )}
      </div>
    );
  }
}

export default ViewChapters;
