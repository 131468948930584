import React, {Component} from 'react';
import axios from 'axios';
import {REACT_APP_BASE_URL} from "../../constants/ActionTypes";
import { Modal, Input, Alert,Row,Col,Switch,message, Select,Button,Spin} from 'antd';
import { UploadOutlined,CloseOutlined } from '@ant-design/icons';
import './modal.css'

const {Option} = Select
const uploadRef = React.createRef();
const iconRef =React.createRef();
class CatModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible:this.props.visible,
      status:this.props.status,
      loading:false,
      id:this.props.data.id,
      headers:this.props.headers,
      name:this.props.data.name || '',
      name_ar:this.props.data.name_ar,
      image_url:this.props.data.image_url || '',
      error :'',
      type:this.props.data.type ||'general',
      validation:false,
      icon: this.props.data.icon,
      iconLoading:false,
      imgLoading:false,
      "in_home":this.props.data.in_home == 1?true:false || false,
      "in_explore":this.props.data.in_explore == 1?true:false || false

    }
  }

  handleOk = () => {

    const {name, name_ar, image_url, icon, headers, id,status,in_explore,in_home,type} = this.state;


      if (name === '' || name_ar=='' ) {
        this.setState({validation:true})
      }
      else{
        this.setState({loading:true},()=>{
          if (status === 'create'){
            axios
            .post(`${REACT_APP_BASE_URL}categories`,{name, type,name_ar, image_url ,icon, in_home, in_explore },{headers})
            .then((res) => {
              message.success('Successfully created')
              this.props.action();
              this.props.created();
              message.success('successfuly created');
            })
            .catch(err =>{
              this.setState({loading:false})
               message.error(err.response.data.errors[0].msg)})
          }
          else if (status === 'edit') {
            axios
            .put(`${REACT_APP_BASE_URL}categories/${id}`,{name, type,name_ar, image_url,icon, in_home ,in_explore},{headers})
            .then(() => {
                this.setState({
                  loading:false
                },()=>{
                  this.props.action();
                  this.props.updated();
                  message.success('successfuly updated')
                } )
            })
            .catch(err => {
              this.setState({loading:false})
              message.error(err.response.data.errors[0].msg)})
        }
        })
      }

  };

  handelIslokedToggle=(checked,e)=> {
    this.setState({in_home:checked});
  }

  handelAvailableToggle=(checked,e)=> {
    this.setState({in_explore:checked});
  }


  handleCancel = () => {
      this.props.action()
    
  };

  handelChange= (e) => {
    e.preventDefault();
    const name = e.target.id;
    this.setState({[name]:e.target.value})
  }

  handleFile = (e,x) => {
    const formData = new FormData();
    this.setState({imgLoading:true})
    const headers = this.state.headers
    formData.append('width', 500);
    formData.append('height', 500);
    formData.append('file', e.target.files[0]);
    axios
    .post(`${REACT_APP_BASE_URL}uploads/books/img`, formData, { headers })
    .then (res => {
      if(x === 'icon'){
        this.setState({icon:res.data.data.file_url,loading:false})
      }
      else{
      this.setState({image_url:res.data.data.file_url,imgLoading:false})}
    })
    .catch(err =>{ 
      this.setState({imgLoading:false})
      message.error(err.response.data.errors[0].msg)})

  }
  handleIcon = (e) => {
    const formData = new FormData();
    this.setState({iconLoading:true})
    const headers = this.state.headers
    formData.append('width', 500);
    formData.append('height', 500);
    formData.append('file', e.target.files[0]);
    axios
    .post(`${REACT_APP_BASE_URL}uploads/books/img`, formData, { headers })
    .then (res => {
      this.setState({icon:res.data.data.file_url,iconLoading:false})
    })
    .catch(err => {
      this.setState({iconLoading:true})
      message.error(err.response.data.errors[0].msg)})
  }

  render() {
    const {visible,type, name_ar ,name, image_url,error,validation,loading,icon,in_home,in_explore} = this.state;
    const { TextArea } = Input;
    
    return (
      <Modal
        title="Category Details"
        visible={visible }
        confirmLoading={loading}
        style={{ top: 10 }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okText='Save'
      >
      <Row >
        <Col span={12}>
        <label>
        <h4>Category Name <span className='req-astrick'>*</span></h4>
        <Input defaultValue={name} id='name' onChange={(e)=>this.handelChange(e)}/>
        {validation && !name && <p className='error'>This field is required!</p>}<br/>
        </label>
        </Col>
        <Col span={12}>
        <label>
        <h4>Category Name (Arabic) <span className='req-astrick'>*</span></h4>
        <Input defaultValue={name_ar} id='name_ar' onChange={this.handelChange}/>
        {validation && !name_ar && <p className='error' >This field is required!</p>}<br/>
        </label>  
        </Col>
      </Row>
      
      <br/>
      <label>
      <h4>Type <span className='req-astrick'>*</span></h4>
        <Select  placeholder='Type' value={type} style={{ width: '100%' }} onChange={(select)=>{this.setState({type:select})}}>
          <Option value="general">General</Option>
          <Option value="faq">FAQ</Option>
        </Select>
      </label> 
      <br/><br/>
      {this.state.type === 'general' &&<Row justify='center'>
    <Col span={8}>
      <Row >
        <Col span={14}>
        <h4>In Home</h4>
        </Col>

        <Switch key='in_home' defaultChecked={in_home} checked={in_home} onChange={this.handelIslokedToggle}  />
      </Row>
    </Col>
    <Col span={8}>
      <Row>
        <Col span={15}>
        <h4 >In Explore </h4></Col>
        <Switch defaultChecked={in_explore} checked={in_explore} key='in_explore' onChange={this.handelAvailableToggle}  />
      </Row>
    </Col>
  </Row>}
  <br/>
      <input type='file' id='image_url' onChange={this.handleFile} ref={uploadRef} style={{display:'none'}}/>
      <input type='file' id='icon' onChange={this.handleIcon} ref={iconRef} style={{display:'none'}}/>
      <Row justify='center'>
        <Col span={8}>
          <span><h4>Category Image  </h4></span>
          {<br/>}
          <Spin spinning={this.state.imgLoading} >
          <img
          loading={this.state.imgLoading}
          className='upImg'
          src={image_url || 'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'}
          alt='category image'
          style={{height:'150px',width:'150px'}}
          onClick={() => uploadRef.current.click()} />
          </Spin>
        </Col>

        <Col span={8}>
          <span><h4>Category Icon </h4> </span>
          {<br/>}
          <Spin spinning={this.state.iconLoading} >
          <img
          className='upImg'
          src={icon || 'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'}
          alt='icon image'
          loading={this.state.iconLoading}
          style={{height:'150px',width:'150px'}}
          onClick={() => iconRef.current.click()} />
          </Spin>
        </Col>

      </Row>

      </Modal>
    );
  }
}

export default CatModal;

